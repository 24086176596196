<template>
    <Admin title="Archived Events">
        <v-container>
            <v-row class="justify-center justify-center mt-12">
                <v-col lg="10">
                    <PastEvents></PastEvents>
                </v-col>
            </v-row>
        </v-container>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import LinkGenerator from "@/components/LinkGenerator.vue";
import Links from "@/components/Links.vue";
import PastEvents from "@/components/PastEvents.vue";
export default {
    components: { Admin, Links, LinkGenerator, PastEvents },
    data: () => ({
        tab: null,
    }),
    beforeMount() {
        this.$store.state.archived = true;
    }
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>