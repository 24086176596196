<template>
    <Guest class="login fill-height">
        <v-container class="px-6 fill-height">
            <v-row justify="center" align-items="center">
                <v-col lg="6">
                    <v-card class="pa-12">
                        <v-card-text>
                            <div class="mb-12">
                                <v-img alt="Vuetify Logo" contain src="@/assets/logo.png" transition="scale-transition" width="100" class="mx-auto" />
                            </div>
                            <h1 class="h1 text-center mb-6">Forgot Password</h1>
                            <p class="text-center">
                                Please enter your email and wait for the password reset link
                            </p>
                            <v-form v-model="valid" v-on:submit="forgot" ref="form" autocomplete="off">
                                <v-text-field name="email" label="E-mail" v-model="email" :rules="emailRules" required outlined></v-text-field>
                                <v-btn style="border-radius: 8px" color="blue darken-4" dark block large elevation="0" type="submit">Submit</v-btn>
                            </v-form>
                            <div class="mt-5">
                                <center><a href="/admin/login">Back to Login Page</a></center>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" v-on:close="dialog.show = false"></AlertDialog>
    </Guest>
</template>

<script>
import Guest from "../layouts/GuestLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
export default {
    data: () => ({
        progressDialog: {
            show: false,
            title: 'Sending Forgot Password Link'
        },
        dialog: {
            show: false,
            title: '',
            body: '',
        },
        valid: true,
        email: "",
        password: "",
        show: false,
    }),
    methods: {
        async forgot(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                this.progressDialog.show = true;
                var response = await this.post('/forgotPassword', {
                    email: this.email,
                });
                if (response.status != 'success') {
                    this.dialog.show = true;
                    this.dialog.title = 'Oops!';
                    this.dialog.body = response.message;
                    this.progressDialog.show = false;
                    return;
                }
                else {
                    this.dialog.show = true;
                    this.dialog.title = 'Success!';
                    this.dialog.body = `
                        <p>Form submitted. Please check your email for the password reset link.</p>
                    `;  
                }
                this.progressDialog.show = false;
            }
        },
    },
    components: {
        Guest,
        AlertDialog,
    },
};
</script>