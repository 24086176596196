import { render, staticRenderFns } from "./AdminLayout.vue?vue&type=template&id=236ec716&scoped=true&"
import script from "./AdminLayout.vue?vue&type=script&lang=js&"
export * from "./AdminLayout.vue?vue&type=script&lang=js&"
import style0 from "./AdminLayout.vue?vue&type=style&index=0&id=236ec716&prod&lang=css&"
import style1 from "./AdminLayout.vue?vue&type=style&index=1&id=236ec716&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236ec716",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VAvatar,VDivider,VIcon,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VMain,VNavigationDrawer,VToolbarTitle})
