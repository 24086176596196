import Vue from 'vue';
import VueRouter from 'vue-router';
import PageNotFound from '../views/errors/PageNotFound.vue';
import Home from '../views/guest/Home.vue'
import SignUp from '../views/authentication/SignUp.vue'
import Login from '../views/authentication/Login.vue';
import auth from '../middleware/auth';
import EventManager from '../views/admin/EventManager.vue';
import ArchivedEvents from '../views/admin/ArchivedEvents.vue';
import EventItem from '../views/admin/EventItem.vue';
import ArchiveEventItem from '../views/admin/ArchiveEventItem.vue';
import Onsite from '../views/admin/Onsite.vue';
import Users from '../views/admin/Users.vue';
import QrCode from '../components/QrCodeScanner.vue';
import session from '../middleware/session';
import Logs from '../views/admin/Logs.vue';
import Reports from '../views/admin/Reports.vue';
import ReportItem from '../views/admin/ReportItem.vue';
import AccountSettings from '../views/admin/AccountSettings.vue';
import PrivacyPolicy from '../views/guest/PrivacyPolicy.vue';
import ForgotPassword from '../views/guest/ForgotPassword.vue';
import ResetPassword from '../views/guest/ResetPassword.vue';
import NewPassword from '../views/guest/NewPassword.vue';

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/privacy-policy',
        name: 'privacy',
        component: PrivacyPolicy,
    },
    {
        path: '/sign-up/:id',
        name: 'signup',
        component: SignUp,
        props: true,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/new-password/:id',
        name: 'new-password',
        component: NewPassword,
    },
    {
        path: '/reset-password/:id',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
    },
    {
        path: '/admin/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: session,
        },
    },
    {
        path: '/admin/event-manager',
        name: 'event-manager',
        component: EventManager,
        meta: {
            requireAuth: true,
            middleware: auth,
        },
        props: true,
    },
    {
        path: '/admin/archived-events',
        name: 'archived-events',
        component: ArchivedEvents,
        props: true,
        meta: {
            requireAuth: true,
            middleware: auth,
        },
    },
    {
        path: '/admin/events/:id',
        name: 'event-item',
        component: EventItem,
        props: true,
        meta: {
            requireAuth: true,
            middleware: auth,
        },
    },
    {
        path: '/admin/archive/events/:id',
        name: 'archive-event-item',
        component: ArchiveEventItem,
        props: true,
        meta: {
            requireAuth: true,
            middleware: auth,
        },
    },
    {
        path: '/admin/onsite-registration',
        name: 'onsite',
        component: Onsite,
        meta: {
            requireAuth: true,
            middleware: auth,
        },
    },
    {
        path: '/admin/users',
        name: 'users',
        component: Users,
        meta: {
            middleware: auth,
        },
    },
    {
        path: '/admin/logs',
        name: 'logs',
        component: Logs,
        meta: {
            middleware: auth,
        },
    },
    {
        path: '/admin/reports',
        name: 'reports',
        component: Reports,
        meta: {
            middleware: auth,
        },
    },
    {
        path: '/admin/reports/:id',
        name: 'reports-item',
        component: ReportItem,
        props: true,
        meta: {
            middleware: auth,
        },
    },
    {
        path: '/admin/settings',
        name: 'settings',
        component: AccountSettings,
        meta: {
            middleware: auth,
        },
    },
    { path: '*', component: PageNotFound, name: '404' },
    { path: '*', component: PageNotFound, name: '401' },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware) {
        return to.meta.middleware(router, next);
    }
    return next();
});

export default router
