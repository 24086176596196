<template>
    <v-card>
        <v-card-title>
            <div class="d-flex" style="width: 100%">
                <div class="flex-grow-1 mr-3">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                </div>
                <div>
                    <v-btn color="warning" @click="dialog = true; resetForm()" v-if="$store.state.archived == false" class="mr-2">Add</v-btn>
                    <v-btn color="success" @click="exportCSV()" v-if="$store.state.events.length != 0">Export CSV</v-btn>
                </div>
            </div>
        </v-card-title>

        <v-data-table :headers="headers" :items="$store.state.events" :search="search" class="elevation-1" :items-per-page="10">
            <template v-slot:item.id="{ item }">
                {{ $store.state.events.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.date="{ item }">
                {{ moment(item.date).format('MMMM DD, YYYY - h:mm A') }}
            </template>
            <template v-slot:item.unregistered="{ item }">
                {{ item.participants - item.registered <= 0 ? 0 : item.participants - item.registered }} 
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                    <v-btn color="info" v-if="$store.state.archived == false" small @click="showEditItem(item);" class="mr-3">
                        Edit
                    </v-btn>
                    <v-btn color="success" v-if="$store.state.archived==false" small :to="`/admin/events/${item.id}`">
                        View
                    </v-btn>
                    <v-btn color="success" v-else small :to="`/admin/archive/events/${item.id}`">
                        View
                    </v-btn>
                </div>
            </template>
        </v-data-table>

        <ConfirmDialog :model="confirmDialog" title="Confirmation" body="Are you sure you want to delete this event?" confirmText="DELETE" confirmColor="red" v-on:close="confirmDialog = false" v-on:confirm="confirmDelete"></ConfirmDialog>


        <v-dialog v-model="dialog" max-width="800px" transition="dialog-transition" persistent eager>
            <v-card>
                <v-card-title>
                    {{ currentItem == null ? 'Add' : 'Edit' }} Event
                </v-card-title>
                <v-card-text>
                    <v-form v-model="valid" v-on:submit.prevent="generateEvent" ref="form">
                        <v-text-field label="Event Title" name="participants" v-model="title" :rules="requiredRules" required outlined></v-text-field>
                        <v-text-field label="Participants" name="participants" v-model="participants" :rules="participantsRules" required outlined type="number"></v-text-field>
                        <h4 class="mb-3 mt-1">Event Schedule</h4>
                        <v-row>
                            <v-col cols="6">
                                <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="date" label="Date" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" @input="dateMenu = false" :min="minDate"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu v-model="timeMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="time" label="Time" prepend-inner-icon="mdi-clock" readonly v-bind="attrs" v-on="on" outlined></v-text-field>

                                    </template>
                                    <!-- <v-date-picker v-model="date" @input="dateMenu = false"></v-date-picker> -->
                                    <v-time-picker v-model="timePicker" format="24hr" :rules="requiredRules"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions v-if="currentItem == null">
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">CANCEL</v-btn>
                    <v-btn text color="success" @click="createEvent">Create</v-btn>
                </v-card-actions>
                <v-card-actions v-if="currentItem != null">
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false; currentItem = null;">CANCEL</v-btn>
                    <v-btn text color="error" @click="deleteEvent">Delete</v-btn>
                    <v-btn text color="success" @click="editEvent">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <AlertDialog :model="alertDialog.show" :title="alertDialog.title" :body="alertDialog.body" @close="alertDialog.show = false"></AlertDialog>
    </v-card>
</template>

<script>
import AlertDialog from './AlertDialog.vue';
import ConfirmDialog from "./ConfirmDialog.vue";
export default {
    data: () => ({
        alertDialog: {
            show: false,
            title: 'Oops!',
            body: 'You cannot edit the event participants less than the current number of registrees.',
        },

        event: null,
        dialog: false,
        valid: null,
        dateMenu: false,
        timeMenu: false,
        title: null,
        participants: 1,
        date: null,
        time: '8:00 AM',
        timePicker: null,
        currentItem: null,
        currentItemData: null,
        confirmDialog: false,
        deleteData: null,
        search: "",
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Event Title", value: "name" },
            { text: "Date and Time", value: "date" },
            { text: "Participants", value: "participants" },
            { text: "Registered", value: "registered", },
            { text: "Unregistered", value: "unregistered", },
            { text: "", value: "actions", sortable: false },
        ],
        events: [],
        participantsRules: [
            (v) => !!v || "This field is required.",
            (v) => {
                if (v <= 0) {
                    return 'Participants must be greater than 0.';
                }
                if (v > 18) {
                    return 'Participants must be less than 18.';
                }
                return true;
            }
        ],
    }),
    computed: {
        minDate() {
            return this.moment().format();
        },
    },
    methods: {
        async resetForm() {
            await this.$refs.form.reset()
            this.title = '';
            this.participants = 1;
            this.date = this.moment(new Date()).format('YYYY-MM-DD');
            this.timePicker = this.moment(new Date().setHours(8, 0)).format('HH:mm');
            this.currentItemData = null;
            this.currentItem = null;
        },
        async createEvent() {
            if (this.$refs.form.validate()) {
                var data = {
                    title: this.title,
                    participants: this.participants,
                    date: this.moment(`${this.date} ${this.timePicker}`),
                };
            
                var response = await this.post('/event/store', data);

                if (response.status != 'success') {
                    return this.alertDialog = {
                        show: true,
                        title: 'Oops!',
                        body: response.message,
                    };
                }

                this.dialog = false;
                this.refreshEvents();
                await this.$refs.form.reset()
                this.title = '';
                this.participants = 1;
                this.date = this.moment(new Date()).format('YYYY-MM-DD');
                this.time = this.moment(new Date().setHours(8, 0)).format('HH:mm');
                this.time = this.moment(new Date().setHours(8, 0)).format('HH:mm');
            }
        },
        showEditItem(item) {
            this.currentItemData = item;
            this.dialog = true;
            this.currentItem = item.id;
            this.title = item.name;
            this.date = this.moment(item.date).format('YYYY-MM-DD');
            this.timePicker = this.moment(item.date).format('HH:mm');
            this.participants = item.participants;
        },
        async editEvent() {
            if (this.participants < this.currentItemData.registered) {
                this.alertDialog.show = true;
                return;
            }
            if (this.$refs.form.validate()) {

                var data = {
                    id: this.currentItem,
                    title: this.title,
                    participants: this.participants,
                    date: this.moment(`${this.date} ${this.timePicker}`),
                };

                xlog(data);

                var response = await this.post('/event/edit', data);
                xlog(response);

                if (response.status == 'success') {
                    this.dialog = false;
                    this.currentItem = null;
                    this.refreshEvents();
                    this.resetForm();
                } else {
                    
                    return this.alertDialog = {
                        show: true,
                        title: 'Oops!',
                        body: response.message,
                    };
         
                }
            }
        },
        deleteEvent() {
            this.confirmDialog = true;
            this.deleteData = this.currentItem;
        },
        async confirmDelete() {
            this.dialog = false;
            this.confirmDialog = false;
            var response = await this.post('/event/delete', { id: this.currentItem });
            this.refreshEvents();
        },
        async exportCSV() {
            if (this.$store.state.events.length == 0) return alert('The event list is empty.');

            var newList = this.renameFields(this.$store.state.events, {
                '_id': 'Event ID',
                'name': 'Event Name',
                'participants': 'Participants',
                'registered': 'Registered',
                'date': 'Scheduled Date',
                'createdAt': 'Created At',
            });

            newList = this.sortFieldsInObjects(newList, [
                'Event ID',
                'Event Name',
                'Participants',
                'Registered',
                'Scheduled Date',
                'Created At',
            ])

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, !this.$store.state.archived ? 'Events.csv' : 'Archived Events.csv');

            var logs = {
                actions: 'Export CSV for Events List.',
            };

            await this.post('/logs/store', logs);
        },
        async viewEventLogs () {
            var logs = {
                actions: 'View Event Links for ' + this.$store.state.currentEvent.name + ' Event.',
            };
            await this.post('/logs/store', logs);
        },  
        formatObjectArray(objectArray, objectFormat) {
            function objectFormatter(initialObject) {
                let resultObject = {};
                for (const key in initialObject) {
                    if (key in objectFormat) {
                        resultObject[key] = objectFormat[key](initialObject[key]);
                    } else {
                        resultObject[key] = initialObject[key];
                    }
                }
                return resultObject;
            }

            return objectArray.map(object => objectFormatter(object));
        }
    },
    async mounted() {
        this.refreshEvents();
        this.date = this.moment(Date.now()).format('YYYY-MM-DD');
    },
    watch: {
        timePicker(time) {
            this.time = this.moment(`2000-01-01 ${time}`).format('h:mm A');
        }
    },
    components: { ConfirmDialog, AlertDialog }
};
</script>

<style scoped>
.copy {
    cursor: pointer;
}

.dec {
    text-decoration: none;
    color: black;
}
</style>