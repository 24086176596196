<template>
    <div>
        <v-btn color="warning" @click="model = true;">
            <span class="mr-3">ADD</span>
            <v-icon small>mdi-qrcode-scan</v-icon>
        </v-btn>
        <v-dialog v-model="model" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col>Add Attendance</v-col>
                        <v-spacer></v-spacer>
                        <div>
                            <v-btn color="success" icon @click="model = false; html5QrcodeScanner.clear">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <div id="reader" width="500px"></div>
                    <div class="text-center mt-5">
                        <h3 :class="{ 'red--text': error, 'green--text': !error }" v-if="message != null">{{ message }}</h3>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" color="success">
            Attendance addedd successfully
        </v-snackbar>

        <v-dialog v-model="deviceModal" max-width="500px">
            <v-card>
                <v-card-title>
                    Device
                </v-card-title>
                <v-card-text>
                    <v-select :items="$store.state.devices" v-model="selectDevice" label="Assign Device" item-value="id" item-text="name"></v-select>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { Html5QrcodeScanner } from "html5-qrcode"
import DeviceSelect from "./DeviceSelect.vue";
export default {
    props: ["id"],
    data: () => ({
        model: false,
        deviceModal: false,
        snackbar: false,
        init: false,
        dialog: false,
        html5QrcodeScanner: null,
        message: null,
        error: true,
        selectDevice: null,
        item: null,
    }),
    methods: {
        async initScanner() {
            await new Promise(resolve => setTimeout(resolve, 500));
            //this.html5QrcodeScanner = new Html5QrcodeScanner("reader", { fps: 1, qrbox: { width: 250, height: 250 } }, false);
            this.html5QrcodeScanner =new Html5QrcodeScanner("reader", { fps: 10, qrbox: 250 });
            this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
            this.html5QrcodeScanner;
        },
        async onScanSuccess(decodedText, decodedResult) {
            var response = await this.post("/registration/attendance", {
                text: decodedText,
                id: this.$props.id,
            });
            if (response.status != "success") {
                this.message = response.message;
                this.error = true;
                await new Promise(resolve => setTimeout(resolve, 3000));
            }
            else {
                this.message = "Attendance added successfully";
                this.error = false;
                this.snackbar = true;
                this.deviceModal = true;
                this.item = JSON.parse(decodedText);
                await new Promise(resolve => setTimeout(resolve, 3000));
                this.message = '';
            }
            var logs = {
                    actions: 'Scanned QR Code for attendance under ' + this.$store.state.currentEvent.name + ' Event.',
                };

            await this.post('/logs/store', logs);
            this.html5QrcodeScanner.clear();

           
        },
        onScanFailure(error) {
            console.warn(`Code scan error = ${error}`);
        }
    },
    watch: {
        model(s) {
            if (s = true)
                this.initScanner();
            this.init = true;
            this.message = null;
        },
        async selectDevice(s) {
            if (s != null) {
                this.deviceModal = false;
                var result = await this.post("/device/select", {
                    regId: this.item.id,
                    devId: s,
                });
                this.refreshDevices();
                this.refreshAttendance();
            }
        }
    },
    components: { DeviceSelect }
}
</script>

<style>

</style>