import axios from "axios";
import store from '@/store/index'
export default async function auth(router, next) {
    var response = await axios({
        method: 'POST',
        url: `${process.env.VUE_APP_BACKEND}/refresh`,
        data: {},
        withCredentials: true,
        headers: {
            'Authorization': process.env.VUE_APP_DEVELOPMENT == 'true' ? `Bearer ${localStorage.token}` : null
        }
    });

    if (response.data.status != 'verified') {
        if(response.data.status=='multiplesession') {
            alert(response.data.message);
        }
        return router.push("/admin/login", () => {});
    }

    return next();
}