<template>
    <Guest>
        <v-container class="px-6 fill-height hidden" v-if="expired" id="v-con">
            <v-row justify="center">
                <v-col sm="12" md="12" lg="12" xl="12">
                    <br>
                    <br>
                    <h1 class="white--text text-center d-lg-none mb-5" style="font-size: 30px">Welcome to the Meralco Smart Grid Interactive Center</h1>
                    <v-alert v-if="expired" dense type="error" border="left">
                        Sign up link has already expired.
                    </v-alert>
                    <p class="white--text text-center d-lg-none" style="font-size: 18px;">Join us at the Smart Grid Interactive Center at Meralco Power Tech, where we exhibit the grid of the future - a grid that's decentralized, decarbonized, and digitalized.</p>

                    <h1 class="white--text text-start d-none d-lg-flex mb-5" style="font-size: 60px">Welcome to the Meralco Smart Grid Interactive Center</h1>
                    <p class="white--text text-start d-none d-lg-flex" style="font-size: 25px;">Join us at the Smart Grid Interactive Center at Meralco Power Tech, where we exhibit the grid of the future - a grid that's decentralized, decarbonized, and digitalized.</p>
                    <div class="d-none d-lg-block mt-3">
                        <br>
                        <img src="@/assets/Meralco Panorama.png" style="width: 100%" alt="">
                        <p>
                            Power Tech, Meralco's technology and innovation training facility, is dedicated to providing sustainable and equitable energy solutions for a brighter future. With a focus on green energy and a circular economy, we are committed to finding innovative solutions that will help reduce energy costs, improve efficiency, and protect the environment.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="px-6 fill-height hidden" v-if="!pageError & !expired" id="v-con">
            <v-row justify="center">
                <v-col sm="12" md="12" lg="6" xl="6">
                    <br>
                    <br>
                    <h1 class="white--text text-center d-lg-none mb-5" style="font-size: 30px">Welcome to the Meralco Smart Grid Interactive Center</h1>
                    <v-alert v-if="expired" dense type="error" border="left">
                        Sign up link has already expired.
                    </v-alert>
                    <p class="white--text text-center d-lg-none" style="font-size: 18px;">Join us at the Smart Grid Interactive Center at Meralco Power Tech, where we exhibit the grid of the future - a grid that's decentralized, decarbonized, and digitalized.</p>

                    <h1 class="white--text text-start d-none d-lg-flex mb-5" style="font-size: 60px">Welcome to the Meralco Smart Grid Interactive Center</h1>
                    <p class="white--text text-start d-none d-lg-flex" style="font-size: 25px;">Join us at the Smart Grid Interactive Center at Meralco Power Tech, where we exhibit the grid of the future - a grid that's decentralized, decarbonized, and digitalized.</p>
                    <div class="d-none d-lg-block mt-3">
                        <br>
                        <img src="@/assets/Meralco Panorama.png" style="width: 100%" alt="">
                        <p>
                            Power Tech, Meralco's technology and innovation training facility, is dedicated to providing sustainable and equitable energy solutions for a brighter future. With a focus on green energy and a circular economy, we are committed to finding innovative solutions that will help reduce energy costs, improve efficiency, and protect the environment.
                        </p>
                    </div>
                </v-col>
                <v-col lg="2" xl="3" class="d-none d-lg-flex"></v-col>
                <v-col v-if="!expired" cols="12" lg="4" xl="3">
                    <v-card class="pa-6" style="border-radius: 8px !important">
                        <v-card-text>
                            <h1 class="h1 mb-3 blue--text text--darken-4">Visitor Registration</h1>
                            <h4 class="black--text">Please fill out the form below.</h4>
                            <p class="mb-3">All fields are requied</p>
                            <v-alert dense :type="left == 0 ? 'error' : 'success'" border="left">
                                You have <strong>{{ left }}</strong> sign-ups left for this link.
                            </v-alert>
                            <br>
                            <v-form v-model="valid" v-on:submit="signUp" ref="form">
                                <v-text-field label="Name of Visitor" placeholder="Full Name" name="fullname" v-model="fullname" :rules="specialRules" required outlined persistent-placeholder />
                                <v-text-field label="Name of Company or School" placeholder="Company or School" name="company" v-model="company" :rules="specialRules" required outlined />
                                <v-text-field label="Role or Designation" placeholder="Ex: Employee or student" name="position" v-model="position" :rules="specialRules" required outlined />
                                <v-text-field label="Email Address" placeholder="email" name="email" v-model="email" :rules="emailRules" required outlined />
                                <v-text-field label="Mobile Number" name="mobile" v-model="mobile" :rules="mobileRules" required outlined prefix="+63" maxlength="10" />

                                <div class="d-flex align-start mb-3">
                                    <v-checkbox v-model="agree" color="blue darken-4" class="pa-0 mt-1" id="agree"></v-checkbox>
                                    <label for="agree" style="cursor: pointer;">By submitting this form, I hereby read and agree to the Terms and Conditions of Meralco.</label>
                                </div>

                                <v-btn v-if="(left > 0 && agree)" style="border-radius: 4px" color="cyan darken-2" dark block type="submit" large>Submit</v-btn>
                                <v-btn v-if="(left == 0 || !agree)" style="border-radius: 4px" disabled block large>Submit</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                    <p class="d-lg-none">
                        <br><br>
                        <img src="@/assets/Meralco Panorama.png" style="width: 100%" alt="">
                        Power Tech, Meralco's technology and innovation training facility, is dedicated to providing sustainable and equitable energy solutions for a brighter future. With a focus on green energy and a circular economy, we are committed to finding innovative solutions that will help reduce energy costs, improve efficiency, and protect the environment.
                    </p>
                </v-col>
            </v-row>
        </v-container>

        <Dialog :model="dialog.show" :title="dialog.title" :body="dialog.body" v-on:close="dialog.show = false"></Dialog>

        <PageNotFound v-if="pageError" noLayout=true />
    </Guest>
</template>

<script>
import Dialog from "../../components/AlertDialog.vue";
import PageNotFound from "../errors/PageNotFound.vue";
import Guest from "../layouts/GuestLayout.vue";
export default {
    props: ["id"],
    components: { Dialog, PageNotFound, Guest },
    data: () => ({
        loading: true,
        pageError: false,
        left: 0,
        dialog: {
            show: false,
            title: '',
            body: ``,
        },
        valid: true,
        fullname: "",
        position: "",
        company: "",
        email: "",
        mobile: "",
        purpose: "",
        show: false,
        agree: false,
        expired: false,
    }),
    methods: {
        async signUp(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                var result = await this.post("/registration/store", {
                    name: this.fullname,
                    position: this.position,
                    company: this.company,
                    email: this.email,
                    mobile: this.mobile,
                    purpose: this.purpose,
                    link: this.$props.id,
                });
                this.dialog.show = true;
                if (result.status != 'success') {
                    this.dialog.title = 'Oops!';
                    this.dialog.body = result.message;
                }
                else {
                    this.dialog.title = 'Success!';
                    this.dialog.body = result.message;
                    this.left -= 1;
                    this.$refs.form.reset();
                }
            }
        },
    },
    async mounted() {
        var result = await this.post(`/link/get`, { url: this.$props.id });
        if (result.data != undefined) {
            this.left = result.data.left;
        } else if(result.linkValid==false) {
            this.expired = true;
        }
        else {
            this.pageError = true;
        }
        this.loading = false;

        var element = document.getElementById("v-con");
        element.classList.remove("hidden");

    },
};
</script>

<style>
.hidden {
    visibility: hidden;
}

.v-sheet.v-card {
    border-radius: 16px !important
}
</style>