<template>
    <v-app id="inspire">
        <ModalIdle v-if="isIdle" />
        <v-navigation-drawer v-model="drawer" app>
            <div class="d-flex pa-4">
                <v-avatar class="mr-3">
                    <v-img src="@/assets/logo.png" />
                </v-avatar>
                <div class=" col pa-0">
                    <h3>Meralco Admin</h3>
                    <p class="mb-0" v-if="currentUser.role=='admin'">Admin</p>
                    <p class="mb-0" v-else>Super Admin</p>
                </div>
            </div>

            <v-divider></v-divider>
            <v-list nav>
                <v-list-item-group v-model="selectedItem" color="orange">
                    <v-list-item v-for="item in items" :key="item.title" link :to="item.route">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <v-list nav>
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app elevation="0" color="transparent" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-app-bar>

        <v-main style="background: #F6F7F9;">
            <slot></slot>
        </v-main>
    </v-app>
</template>

<script>
import ModalIdle from '@/components/ModalIdle';
export default {
    components: {
        ModalIdle
    },
    props: ['title'],
    data: () => ({
        drawer: null,
        selectedItem: 0,
        currentUser: '',
        items: [
            {
                title: "Event Manager",
                icon: "mdi-calendar",
                route: "/admin/event-manager",
            },
            {
                title: "Archived Events",
                icon: "mdi-calendar",
                route: "/admin/archived-events",
            },
            {
                title: "On-site Registration",
                icon: "mdi-account-plus",
                route: "/admin/onsite-registration",
            },
            {
                title: "Users",
                icon: "mdi-account-group",
                route: "/admin/users",
            },
            {
                title: "Audit Logs",
                icon: "mdi-list-box",
                route: "/admin/logs",
            },
            {
                title: "Reports",
                icon: "mdi-clipboard-list",
                route: "/admin/reports",
            },
        ],
    }),
    methods: {
        async logout() {
            await this.post('/logout');
            this.$router.push("/admin/login", () => {});
        },
        async getCurrentUser () {
            var response = await this.post('/refresh');
            this.currentUser = response.data;
        },

    },
    mounted() {
        this.getCurrentUser();
    },
    
    computed: {
		isIdle() {
			return this.$store.state.idleVue.isIdle;
		}
	},
};
</script>

<style>
.v-sheet.v-card {
    border-radius: 24px !important;
    overflow: hidden;
}

.v-window.v-item-group {
    border-radius: 24px !important;
    overflow: hidden;
}

.v-dialog {
    border-radius: 24px;
}
</style>


<style scoped>
.v-main {
    background: rgb(39, 70, 160) !important;
    background: linear-gradient(62deg, rgba(39, 70, 160, 1) 0%, rgba(116, 221, 221, 1) 100%) !important;
    /* background-image: url("@/assets/background.svg");
    background-size: cover; */
}
</style>