<template>
    <Guest class="login fill-height">
        <v-container class="px-6 fill-height">
            <v-row justify="center" align-items="center">
                <v-col lg="6">
                    <v-card class="pa-12">
                        <v-card-text>
                            <div class="mb-12">
                                <v-img alt="Vuetify Logo" contain src="@/assets/logo.png" transition="scale-transition" width="100" class="mx-auto" />
                            </div>
                            <h1 class="h1 text-center mb-6">Privacy Notice</h1>
                            <p class="text-center">
                                Meralco Power Academy (MPA) values your privacy.
                            </p>
                            <p class="text-center">
                                We collect your name, position, company name, email address, and mobile number solely to facilitate your registration to the Grid Discovery Zone at Meralco Power Tech. These are processed by our authorized personnel who hold such information under strict confidentiality. These are kept for a period of five (5) years and are subsequently disposed of properly.
                            </p>
                            <p class="text-center">
                                To know more about how we protect your data, you may visit <a href="https://company.meralco.com.ph/privacy-statement">https://company.meralco.com.ph/privacy-statement</a> To exercise your data subject rights, please contact <a href = "mailto: cdpo@meralco.com.ph">cdpo@meralco.com.ph</a>.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </Guest>
</template>

<script>
import Guest from "../layouts/GuestLayout.vue";

export default {
    components: {
        Guest,
    },
};
</script>