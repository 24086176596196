<template>
    <div>
        <v-btn color="success" @click="model = true;" class="mr-2">
            GENERATE
        </v-btn>
        <v-dialog v-model="model" max-width="800px">
            <v-card>
                <v-card-title>
                    <h3>Link Generator</h3>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="model = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    Generate link for every attendees. {{ signUpsLeft< 0? '0' : signUpsLeft }} participants slots left. </v-card-subtitle>
                        <v-card-text>
                            <v-form v-model="valid" v-on:submit.prevent="generateLink" ref="form">
                                <!-- <v-select :items="this.$store.state.events" label="Event" name="event" v-model="event" :rules="requiredRules" required outlined item-text="name" item-value="id">
                </v-select> -->
                                <v-text-field label="Number of sign-ups (per link)" name="attendees" v-model="attendees" :rules="validateNumber" required outlined type="number" class="mt-3"></v-text-field>
                                <v-combobox v-model="recipients" chips label="Recipients" multiple outlined append-icon="" den :rules="validateEmail" @change="validateEmail">
                                    <template v-slot:selection="{ attrs, item, select, selected }">
                                        <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                                            {{ item }}
                                        </v-chip>
                                    </template>
                                </v-combobox>
                                <v-checkbox v-model="sendBlast" label="Send links to recipients via email blast." class="pt-0 mt-0"></v-checkbox>
                                <div v-if="sendBlast">
                                    <v-textarea outlined name="input-7-4" label="Custom Message" placeholder="Type a message..." v-model="customMessage" :disabled="!sendBlast"></v-textarea>
                                </div>

                                <v-btn color="orange" :dark="recipients.length != 0" block large type="submit" :disabled="recipients.length == 0">Generate</v-btn>
                            </v-form>
                        </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="80%" persistent>
            <v-card>
                <v-card-title class="mb-6 text-center d-flex">
                    <h5>Links generated successfully</h5>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="linksGenerated" :search="search" class="elevation-1" :items-per-page="10">
                    <template v-slot:item.id="{ item }">
                        {{ linksGenerated.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.title="{ item }">
                        {{ item.event.name }}
                    </template>
                    <template v-slot:item.url="{ item }">
                        <v-row>
                            <router-link :to="`/sign-up/${item.url}`" class="dec">{{ item.url }}</router-link>
                            <v-spacer></v-spacer>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon class="copy mr-3" v-on="on" @click="copyLink(item.url)">mdi-content-copy</v-icon>
                                </template>
                                <span>Copy Link</span>
                            </v-tooltip>
                        </v-row>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                        {{ moment(item.createdAt).format('MMMM DD, YYYY') }}
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>

        <AlertDialog :model="alertDialog.show" :title="alertDialog.title" :body="alertDialog.body" @close="alertDialog.show = false"></AlertDialog>
    </div>
</template>

<script>
import AlertDialog from './AlertDialog.vue';
export default {
    components: { AlertDialog },
    data: () => ({
        model: false,
        dialog: false,
        alertDialog: {
            show: false,
            title: 'Oops!',
            body: 'You cannot exceed the maximum participants allowed in this event.',
        },
        search: '',
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Sign-ups", value: "left" },
            { text: "Created", value: "createdAt" },
            { text: "Recipient", value: "email" },
            { text: "Link", value: "url", },
        ],
        linksGenerated: [],
        items: [],
        valid: null,
        dateMenu: false,
        timeMenu: false,
        event: null,
        attendees: 1,
        recipients: [],
        sendBlast: true,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        time: '8:00 AM',
        timePicker: null,
        customMessage: '',
        validateNumber: [
            (num) => {
                if (num === null || num === '') {
                    return 'A number is required';
                }
                if (Number.isNaN(num)) {
                    return 'Must be a valid number';
                }
                if (num <= 0) {
                    return 'Must be greater than 0';
                }
                return true;
            }
        ],
        validateEmail: [   
            (email) => {
                if(email.length > 0) {
                    let tmp = "";
                    for (let i = 0; i < email.length; i++) {
                        if (email != '' && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email[i]))) {
                            tmp = tmp + email[i];
                            if(i != email.length-1) {
                                tmp = tmp + " , ";
                            }
                        }
                    }
                    if(tmp != "") {
                        return "Invalid Email " + tmp
                    }
                    else {
                        return true;
                    }
                }
                return true;
            }
        ]
    }),
    computed: {
        signUpsLeft() {
            var currentEvent = this.$store.state.currentEvent;
            if (currentEvent == null) {
                return 0;
            }
            var participants = currentEvent.participants;
            var links = this.$store.state.links;

            var signups = 0;
            var disapproved = 0;
            for (var l of links) {
                if(l.disapproved !=0) {
                    disapproved += l.disapproved;
                }
                signups += l.signups;
            }
            return participants - signups + disapproved;
        }
    },
    methods: {
        async generateLink() {
            if (this.recipients.length * this.attendees > this.signUpsLeft) {
                this.alertDialog.show = true;
                return;
            }

            if (this.$refs.form.validate()) {
                var data = {
                    event: this.event,
                    quantity: this.attendees,
                    recipients: this.recipients,
                    sendBlast: this.sendBlast,
                    customMessage: this.customMessage,
                };

                var response = await this.post('/link/store', data);

                if (response.status == 'success') {
                    this.linksGenerated = response.data;
                    this.dialog = true;
                    this.refreshLinks();
                    await this.$refs.form.reset()
                    this.attendees = 1;
                    this.customMessage = '';
                    this.recipients = [];
                    this.sendBlast = false;

                    var logs = {
                        actions: 'Generate Event Link for ' + this.$store.state.currentEvent.name + ' Event.',
                    };

                    var response = await this.post('/logs/store', logs);
                }
            }
        },
        remove(item) {
            this.recipients.splice(this.recipients.indexOf(item), 1)
        },
    },
    async mounted() {
        this.event = this.$route.params.id;
    }
};
</script>

<style>

</style>