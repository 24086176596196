import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentEvent: null,
        token: null,
        registrations: [],
        attendance: [],
        links: [],
        events: [],
        devices: [],
        archived: false,
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
