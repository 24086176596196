<template>
    <Guest class="login fill-height">
        <v-container class="px-6 fill-height">
            <v-row justify="center" align-items="center">
                <v-col lg="6">
                    <v-card class="pa-12">
                        <v-card-text>
                            <div class="mb-12">
                                <v-img alt="Vuetify Logo" contain src="@/assets/logo.png" transition="scale-transition" width="100" class="mx-auto" />
                            </div>
                            <h1 class="h1 text-center mb-6">Reset Password</h1>
                            <v-alert dense v-if="this.message" type="error" border="left">
                                {{this.error}}
                            </v-alert>
                            <div v-else>
                                <p class="text-center">
                                    Please enter your new password
                                </p>
                                <v-form v-model="valid" v-on:submit="reset" ref="form" autocomplete="off">
                                    <v-text-field name="password" label="New Password" v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" required outlined error-count="7" @change="validate()" @input="validate()"></v-text-field>
                                    <v-text-field name="confirmPassword" label="Confirm New Password" v-model="confirmPassword" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordConfirm" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword" required outlined error-count="7" @change="validate()" @input="validate()"></v-text-field>
                                    <v-btn style="border-radius: 8px" color="blue darken-4" dark block large elevation="0" type="submit">Submit</v-btn>
                                </v-form>
                            </div>
                            <div class="mt-5">
                                <center><a href="/admin/login">Back to Login Page</a></center>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" @close="close()"></AlertDialog>

        <ProgressDialog :show="progressDialog.show" :title="progressDialog.title"></ProgressDialog>
    </Guest>
</template>

<script>
import Guest from "../layouts/GuestLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import ProgressDialog from "@/components/ProgressDialog.vue";
import { onBeforeMount } from "vue";
export default {
    data() {
        return {
            progressDialog: {
                show: false,
                title: 'Resetting Password'
            },
            dialog: {
                show: false,
                title: '',
                body: '',
            },
            valid: true,
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            message: false,
            error: "",
            passwordConfirm: [
                v => !!v || "Confirm Password required",
                v => v===this.password || "Passwords do not match"
            ]
        };
    },
    methods: {
        async reset(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                this.progressDialog.show = true;
                var response = await this.post('/resetPassword', {
                    password: this.password,
                    link: this.$route.params.id,
                });
                if (response.status != 'success') {
                    this.dialog.show = true;
                    this.dialog.title = 'Oops!';
                    this.dialog.body = response.message;
                    this.progressDialog.show = false;
                    return;
                }
                else {
                    this.dialog.show = true;
                    this.dialog.title = 'Success!';
                    this.dialog.body = `
                        <p>Password has been reset successfully.</p>
                    `;  
                }
                this.progressDialog.show = false;
            }
        },
        async checkLink() {
            var response = await this.post('/checkLink', {
                    link: this.$route.params.id,
                });
                if (response.status != 'success') {
                    this.message = true;
                    this.error = response.message;
                    return;
                }
        },
        async close() {
            this.dialog.show = false; 
            this.$router.go() 
        },
        async validate() {
            this.$refs.form.validate();
        }
    },
    beforeMount() {
        this.checkLink();
    },
    mounted(){
        this.$refs.form.validate();
    },
    components: {
        Guest,
        AlertDialog,
        ProgressDialog
    },
};
</script>