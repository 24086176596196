<template>
    <div title="Attendance">
        <v-card>
            <v-card-title>
                <div class="row">
                    <div class="col pr-0">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                    </div>
                    <div class="d-flex col-auto">
                        <div class="mr-3">
                            <QrCode :id="$props.eventId"></QrCode>
                        </div>
                        <div>
                            <QrCodeReturn></QrCodeReturn>
                        </div>
                        <!-- <v-btn color="success" @click="exportCSV()" v-if="this.$store.state.attendance.length != 0" class="ml-3">Export CSV</v-btn> -->
                    </div>
                </div>
            </v-card-title>
            <v-data-table :headers="headers" :items="$store.state.attendance" :search="search" class="elevation-1" :items-per-page="10">
                <template v-slot:item.id="{ item }">
                    {{ $store.state.attendance.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.event="{ item }">
                    {{ item.event.name }}
                </template>
                <template v-slot:item.device="{ item }">
                    <DeviceSelect :item="item"></DeviceSelect>
                </template>


                <template v-slot:item.status="{ item }">
                    <v-chip small color="warning" v-if="item.status == 'pending'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="success" v-if="item.status == 'approved'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="error" v-if="item.status == 'disapproved'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="success" v-if="item.status == 'attended'">{{ capitalize(item.status) }}</v-chip>
                </template>

                <template v-slot:item.attended="{ item }">
                    {{ moment(item.attended).format('MMMM DD, YYYY h:mm A') }}
                </template>

                <template v-slot:item.left="{ item }">
                    <span v-if="item.left != null">{{ moment(item.left).format('MMMM DD, YYYY h:mm A') }}</span>
                </template>

                <template v-slot:item.link="{ item }">
                    <div class="d-flex" v-if="item.link != ''">
                        <router-link :to="`/sign-up/${item.link}`" class="dec">{{ item.link }}</router-link>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <!-- <a v-on="on" :href="`/sign-up/${item.link}`" @click.prevent="copyLink(item.link); snackbar = true">{{ item.link }}</a> -->
                                <v-icon class="copy mr-3" @click="copyLink(item.link); snackbar.show = true; snackbar.text = 'Copied successfully'" v-on="on">mdi-content-copy</v-icon>
                            </template>
                            <span>Copy Link</span>
                        </v-tooltip>
                    </div>
                    <span v-if="item.link == ''">Registered On-site</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex">
                        <v-btn color="info" small @click="info = true; selected = item;" class="mr-2">
                            View 
                            <v-icon right>mdi-eye</v-icon>
                        </v-btn>
                        <QrCodeViewer :item="item" :eventId="$props['eventId']"></QrCodeViewer>
                    </div>
                </template>
            </v-data-table>
        </v-card>

        <v-snackbar v-model="snackbar.show" color="success" timeout="1500">
            {{ snackbar.text }}
        </v-snackbar>

        <v-dialog v-model="info" max-width="50%">
            <v-card>
                <v-card-title class="mb-6 text-center d-flex">
                    <h5>Registration Info</h5>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="info = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Full Name</td>
                                    <td>{{ selected.name }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Position</td>
                                    <td>{{ selected.position }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Company Name</td>
                                    <td>{{ selected.company }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Email</td>
                                    <td>{{ selected.email }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Mobile</td>
                                    <td>{{ selected.mobile }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Status</td>
                                    <td>
                                        <v-chip small color="warning" v-if="selected.status == 'pending'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="success" v-if="selected.status == 'approved'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="success" v-if="selected.status == 'attended'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="error" v-if="selected.status == 'disapproved'">{{ capitalize(selected.status) }}</v-chip>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Device</td>
                                    <td>
                                        <v-chip @click:close="confirmDialog.show = true" v-if="selected.deviceDetails" :color="selected.left == null ? 'error' : 'success'" dark small>{{ selected.deviceDetails.name }}</v-chip>
                                        <v-chip @click:close="confirmDialog.show = true" v-else :color="selected.left == null ? 'error' : 'success'" dark small>No device assigned</v-chip>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Registered</td>
                                    <td>{{ moment(selected.date).format('MMMM DD, YYYY - h:mm A') }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Attended</td>
                                    <td>{{ moment(selected.attended).format('MMMM DD, YYYY - h:mm A') }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Left</td>
                                    <td v-if="selected.left != null">{{ moment(selected.left).format('MMMM DD, YYYY - h:mm A') }} </td>
                                    <td v-else>--</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Link Used</td>
                                    <td>
                                        <div class="d-flex">
                                            <router-link :to="`/sign-up/${selected.link}`" class="dec">{{ selected.link }}</router-link>
                                            <v-spacer></v-spacer>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="copy mr-3" v-on="on" @click="copyLink(selected.link); snackbar.show = true; snackbar.text = 'Copied successfully'">mdi-content-copy</v-icon>
                                                </template>
                                                <span>Copy Link</span>
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <div v-if="selected != null">
                    <v-card-actions v-if="selected.status == 'pending'" class="pb-4">
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="disapprove(selected.id)">
                            Disapprove
                        </v-btn>
                        <v-btn color="success" class="mr-3" @click="approve(selected.id)">
                            Approve
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <AlertDialog :title="alert.title" :body="alert.subtitle" :model="alert.show" @close="alert.show = false"></AlertDialog>
        <ConfirmDialog :title="dialog.title" :body="dialog.subtitle" :confirmColor="dialog.confirmColor" :confirmText="dialog.confirmText" :model="dialog.show" @close="dialog.show = false" @confirm="dialog.function()"></ConfirmDialog>
    </div>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import DeviceSelect from "./DeviceSelect.vue";
import QrCode from "@/components/QrCodeScanner.vue";
import QrCodeViewer from "@/components/QrCodeViewer.vue";
import QrCodeReturn from "@/components/QrCodeReturn.vue";

export default {
    components: { Admin, ConfirmDialog, AlertDialog, DeviceSelect, QrCode, QrCodeViewer, QrCodeReturn },
    props: ["eventId"],
    data: () => ({
        //id: window.location.pathname.split('/')[3],
        qrcode: false,
        info: false,
        alert: {
            show: false,
            title: '',
            subtitle: '',
        },
        dialog: {
            show: false,
            title: '',
            subtitle: '',
            confirmColor: '',
            confirmText: '',
            function: () => { },
        },
        snackbar: {
            show: false,
            text: '',
        },
        dialog: false,
        search: null,
        selected: null,
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Full Name", value: "name" },
            { text: "Link Used", value: "link" },
            { text: "Device", value: "device" },
            { text: "Attended", value: "attended" },
            { text: "Left", value: "left" },
            { text: "Actions", value: "actions" },
        ],
        users: [],
    }),
    methods: {
        getColor(calories) {
            if (calories > 400) return 'red'
            else if (calories > 200) return 'orange'
            else return 'green'
        },
        approve() {
            this.dialog = {
                show: true,
                title: 'Approve',
                subtitle: 'Are you sure you want to approve this registration?',
                confirmColor: 'success',
                confirmText: 'Approve',
                function: async () => {
                    this.hideDialog();
                    var result = await this.post('/registration/edit', {
                        'id': this.selected.id,
                        'status': 'approved',
                    });
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: 'Approved successfully.'
                    }
                    this.refreshAttendance()
                    this.refreshDevices()
                    }
            }
        },
        disapprove(id) {
            this.dialog = {
                show: true,
                title: 'Disapprove',
                subtitle: 'Are you sure you want to disapprove this registration?',
                confirmColor: 'error',
                confirmText: 'Disapprove',
                function: async () => {
                    var result = await this.post('/registration/edit', {
                        'id': this.selected.id,
                        'status': 'disapproved',
                    });
                    this.hideDialog();
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: 'Disapproved successfully.'
                    }
                    this.refreshAttendance()
                    this.refreshDevices()
                }
            }
        },
        hideDialog() {
            this.dialog.show = false;
            this.info = false;
        },
        async exportCSV() {
            var newList = this.renameFields(this.$store.state.attendance, {
                '_id': 'Registration ID',
                'name': 'Full Name',
                'company': 'Company',
                'position': 'Position',
                'email': 'Email',
                'mobile': 'Mobile Number',
                'status': 'Status',
                'device': 'Device',
                'left': 'Left',
                'createdAt': 'Registered',
                'attended': 'Attended',
                'recipient': 'Invitation Recipient',
                'link': 'Link Used',
            });

            newList = this.sortFieldsInObjects(newList, [
                'Registration ID',
                'Full Name',
                'Company',
                'Position',
                'Email',
                'Mobile Number',
                'Status',
                'Attended',
                'Left',
                'Registered',
                'Registered',
                'Invitation Recipient',
                'Link Used',
            ])

            newList = this.formatObjectArray(newList, {
                'Status': (value) => this.toTitleCase(value),
                'Mobile Number': (value) => `+63${value}`,
                'Link Used': (value) => {
                    if (value == 'onsite') return 'Registered On-site';
                    return `${window.location.origin}/sign-up/${value}`;
                }
            })

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, `${this.$store.state.currentEvent.name} Attendance.csv`);

            var logs = {
                actions: 'Export CSV For ' + this.$store.state.currentEvent.name + ' Event Attendance List.',
            };

            var response = await this.post('/logs/store', logs);
        },
    },
    async mounted() {
        this.refreshAttendance()
        this.refreshDevices()

        var logs = {
                actions: 'View Attendance List for ' + this.$store.state.currentEvent.name + ' Event.',
            };

       await this.post('/logs/store', logs);
    },
};
</script>

<style>
.dec {
    text-decoration: none;
    color: black;
}
</style>
