<template>
    <v-card>
        <v-card-title>
            <div class="d-flex" style="width: 100%">
                <div class="flex-grow-1 mr-3">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                </div>
                <div>
                    <v-btn color="success" @click="exportCSV()" v-if="$store.state.events.length != 0">Export CSV</v-btn>
                </div>
            </div>
        </v-card-title>

        <v-data-table :headers="headers" :items="$store.state.events" :search="search" class="elevation-1" :items-per-page="10">
            <template v-slot:item.id="{ item }">
                {{ $store.state.events.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.date="{ item }">
                {{ moment(item.date).format('MMMM DD, YYYY - h:mm A') }}
            </template>
            <template v-slot:item.unregistered="{ item }">
                {{ item.participants - item.registered <= 0 ? 0 : item.participants - item.registered }} </template>
                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex">
                            <v-btn color="success" small :to="`/admin/archive/events/${item.id}`">
                                View
                            </v-btn>
                        </div>
                    </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        event: null,
        dialog: false,
        valid: null,
        dateMenu: false,
        timeMenu: false,
        title: null,
        participants: 1,
        date: null,
        time: '8:00 AM',
        timePicker: null,
        currentItem: null,
        currentItemData: null,
        confirmDialog: false,
        deleteData: null,
        search: "",
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Event Title", value: "name" },
            { text: "Date and Time", value: "date" },
            { text: "Participants", value: "participants" },
            { text: "Registered", value: "registered", },
            { text: "Unregistered", value: "unregistered", },
            { text: "", value: "actions", sortable: false },
        ],
        events: [],
        participantsRules: [
            (v) => !!v || "This field is required.",
            (v) => {
                if (v <= 0) {
                    return 'Participants must be greater than 0.';
                }
                return true;
            }
        ],
    }),
    computed: {
        minDate() {
            return this.moment().format();
        },
    },
    methods: {
        async exportCSV() {
            if (this.$store.state.events.length == 0) return alert('The event list is empty.');

            var newList = this.renameFields(this.$store.state.events, {
                '_id': 'Event ID',
                'name': 'Event Name',
                'participants': 'Participants',
                'registered': 'Registered',
                'date': 'Scheduled Date',
                'createdAt': 'Created At',
            });

            newList = this.sortFieldsInObjects(newList, [
                'Event ID',
                'Event Name',
                'Participants',
                'Registered',
                'Scheduled Date',
                'Created At',
            ])

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, !this.$store.state.archived ? 'Events.csv' : 'Archived Events.csv');

            var logs = {
                actions: 'Export CSV for Events List.',
            };

            await this.post('/logs/store', logs);
        },
    },
    async mounted() {
        this.refreshEvents();
        this.date = this.moment(Date.now()).format('YYYY-MM-DD');
    },
};
</script>

<style scoped>
.copy {
    cursor: pointer;
}

.dec {
    text-decoration: none;
    color: black;
}
</style>