<template>
    <Admin title="On-site Registration">
        <v-container>
            <v-row class="justify-center mt-12">
                <v-col lg="8">
                    <v-card>
                        <v-card-title>Register User</v-card-title>
                        <v-card-subtitle>
                            Registration form for onsite visitors.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-form v-model="valid" v-on:submit="signUp" ref="form">
                                <v-select :items="this.$store.state.events" label="Event" name="event" v-model="event" :rules="requiredRules" required outlined item-text="name" item-value="id"></v-select>
                                <v-text-field label="Full Name" name="fullname" v-model="fullname" :rules="requiredRules" required outlined />
                                <v-text-field label="Position" name="position" v-model="position" :rules="requiredRules" required outlined />
                                <v-text-field label="Company Name" name="company" v-model="company" :rules="requiredRules" required outlined />
                                <v-text-field label="E-mail" name="email" v-model="email" :rules="emailRules" required outlined />
                                <v-text-field label="Mobile Number" name="mobile" v-model="mobile" :rules="mobileRules" required outlined prefix="+63" maxlength="10" />
                                <!-- <v-text-field label="Purpose of Visit" name="purpose" v-model="purpose" :rules="requiredRules" required outlined /> -->
                                <v-btn style="border-radius: 8px" color="blue darken-4" dark block type="submit" large>Submit</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" v-on:close="dialog.show = false"></AlertDialog>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
export default {
    components: { Admin, AlertDialog },
    data: () => ({
        loading: true,
        pageError: false,
        left: 0,
        dialog: {
            show: false,
            title: '',
            body: ``,
        },
        valid: true,
        event: null,
        fullname: "",
        position: "",
        company: "",
        email: "",
        mobile: "",
        purpose: "",
        show: false,
    }),
    methods: {
        async signUp(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                var result = await this.post("/registration/onsite", {
                    event: this.event,
                    name: this.fullname,
                    position: this.position,
                    company: this.company,
                    email: this.email,
                    mobile: this.mobile,
                    purpose: this.purpose,
                });
                this.dialog.show = true;
                if (result.status != 'success') {
                    this.dialog.title = 'Oops!';
                    this.dialog.body = result.message;
                }
                else {
                    this.dialog.title = 'Success!';
                    this.dialog.body = `
                        <p>${result.message} Please scan you QR Code below.</p>
                        <center>
                        <img src="${result.data}" style="width: 100%;">
                        </center>
                    `;
                    this.$refs.form.reset();
                }
            }
        },
    },
    beforeMount() {
        this.$store.state.archived = false;
    },
    async mounted() {
        this.availableEvents();
    }
};
</script>