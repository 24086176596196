<template>
    <v-dialog v-model="model" max-width="300" persistent>
        <v-card>
            <v-card-title class="text-h5">
                {{ title }} 
            </v-card-title>

            <v-card-text>
                <span v-html="body"></span>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="closeModal"> OK </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ["title", "body", "model"],
    methods: {
        closeModal() {
            this.$emit("close", false);
        },
        copyText(tmp) {
            var input = document.createElement("textarea");
            input.value = tmp;
            document.body.appendChild(input);
            try {
                input.select();
                input.click();
                input.focus();
                var successful = document.execCommand("copy");
            }
            catch (err) {

            }
            document.body.removeChild(input);
        }
        
    },
};
</script>

<style>
</style>