<template>
    <Admin title="">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col lg="10">
                    <h1 class="white--text">{{  event.name ?? ''  }}</h1>
                    <v-tabs class="mb-6" v-model="tab" background-color="transparent" dark>
                        <v-tab>Links</v-tab>
                        <!-- <v-tab>Registrations</v-tab> -->
                        <v-tab>Attendance</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <ArchiveLinks :eventId="event.id"></ArchiveLinks>
                        </v-tab-item>
                        <v-tab-item>
                            <ArchiveAttendance :eventId="event.id"></ArchiveAttendance>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import ArchiveLinks from "@/components/ArchiveLinks.vue";
import ArchiveAttendance from "@/components/ArchiveAttendance.vue";
export default {
    components: { Admin, ArchiveLinks, ArchiveAttendance },
    props: ['id'],
    data: () => ({
        tab: null,
        event: {},
    }),
    async mounted() {
        var result = await this.post('/event/get', {
            url: this.$route.params.id,
        })
        this.event = result.data;
        this.$store.state.currentEvent = result.data;    

        var logs = {
            actions: 'View Event Links for ' + this.$store.state.currentEvent.name + ' Event.',
        };

        var response = await this.post('/logs/store', logs);
         
    }
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>