<template>
    <div title="Registrations">
        <v-card>
            <v-card-title>
                <div class="d-flex">
                    <h3 class="mr-5">Registrations</h3>
                    <div>
                        <div style="width: 150px">

                        </div>
                    </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-data-table :headers="headers" :items="$store.state.registrations" :search="search" class="elevation-1" :items-per-page="10" :custom-filter="filterPerfectMatch">
                <!-- Header -->
                <template v-slot:header.status="{ header }">
                    <div id="vsel">
                        <v-select :items="statuses" label="Status" v-model="search" dense hide-details single-line solo flat item-text="name" item-value="value"></v-select>
                    </div>
                </template>

                <!-- No data -->
                <template v-slot:no-data v-if="!loaded">
                    <div class="d-flex align-center justify-center">
                        <div>
                            <v-progress-circular indeterminate color="green" size="24" width="3"></v-progress-circular>
                        </div>
                        <div class="ml-4">
                            <span>Loading data. Please wait...</span>
                        </div>
                    </div>
                </template>

                <!-- Items -->
                <template v-slot:item.id="{ item }">
                    {{ $store.state.registrations.indexOf(item) + 1 }}
                </template>
                <template v-slot:item.recipient="{ item }">
                    {{ item.recipient == '' ? item.email : item.recipient }}
                </template>
                <template v-slot:item.event="{ item }">
                    {{ item.event.name }}
                </template>
                <template v-slot:item.device="{ item }">
                    <DeviceSelect :item="item"></DeviceSelect>
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex align-center">
                        <v-spacer></v-spacer>
                        <QrCodeViewer :item="item" :eventId="$props['eventId']" v-if="item.status != 'pending' && item.status != 'disapproved'"></QrCodeViewer>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="info = true; selected = item;">
                                    <v-list-item-title>View Registration</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="resendQrCode(item)" v-if="item.status != 'pending' && item.status != 'disapproved'">
                                    <v-list-item-title>Resend QR Code</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip small color="warning" v-if="item.status == 'pending'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="success" v-if="item.status == 'approved'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="success" v-if="item.status == 'attended'">{{ capitalize(item.status) }}</v-chip>
                    <v-chip small color="error" v-if="item.status == 'disapproved'">{{ capitalize(item.status) }}</v-chip>
                </template>

                <template v-slot:item.createdAt="{ item }">
                    {{ moment(item.createdAt).format('MMMM DD, YYYY') }}
                </template>

                <template v-slot:item.link="{ item }">
                    <div class="d-flex" v-if="item.link != 'onsite'">
                        <router-link :to="`/sign-up/${item.link}`" class="dec">{{ item.link }}</router-link>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <!-- <a v-on="on" :href="`/sign-up/${item.link}`" @click.prevent="copyLink(item.link); snackbar = true">{{ item.link }}</a> -->
                                <v-icon class="copy mr-3" @click="copyLink(item.link); snackbar.show = true; snackbar.text = 'Copied successfully'" v-on="on">mdi-content-copy</v-icon>
                            </template>
                            <span>Copy Link</span>
                        </v-tooltip>
                    </div>
                    <span v-if="item.link == 'onsite'">Registered On-site</span>
                </template>
            </v-data-table>

            <v-card-text>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mr-3" @click="approveAll" v-if="this.$store.state.registrations && this.$store.state.registrations.length != 0">Approve All</v-btn>
                    <v-btn color="success" @click="exportCSV()" v-if="this.$store.state.registrations && this.$store.state.registrations.length != 0">Export CSV</v-btn>
                </div>
            </v-card-text>
        </v-card>

        <v-snackbar v-model="snackbar.show" color="success" timeout="1500">
            {{ snackbar.text }}
        </v-snackbar>

        <v-dialog v-model="info" max-width="50%">
            <v-card>
                <v-card-title class="mb-6 text-center d-flex">
                    <h5>Registration Info</h5>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="info = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Recipient</td>
                                    <td>{{ selected.recipient == '' ? selected.email : selected.recipient }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Full Name</td>
                                    <td>{{ selected.name }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Position</td>
                                    <td>{{ selected.position }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Company Name</td>
                                    <td>{{ selected.company }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Email</td>
                                    <td>{{ selected.email }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Mobile</td>
                                    <td>{{ selected.mobile }}</td>
                                </tr>
                                <!-- <tr>
                                    <td class="font-weight-bold">Purpose of Visit</td>
                                    <td>{{ selected.purpose }}</td>
                                </tr> -->
                                <tr>
                                    <td class="font-weight-bold">Status</td>
                                    <td>
                                        <v-chip small color="warning" v-if="selected.status == 'pending'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="success" v-if="selected.status == 'approved'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="error" v-if="selected.status == 'disapproved'">{{ capitalize(selected.status) }}</v-chip>
                                        <v-chip small color="success" v-if="selected.status == 'attended'">{{ capitalize(selected.status) }}</v-chip>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Registered</td>
                                    <td>{{ moment(selected.date).format('MMMM DD, YYYY - h:mm A') }}</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Link Used</td>
                                    <td>
                                        <div class="d-flex" v-if="selected.link != 'onsite'">
                                            <router-link :to="`/sign-up/${selected.link}`" class="dec">{{ selected.link }}</router-link>
                                            <v-spacer></v-spacer>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="copy mr-3" v-on="on" @click="copyLink(selected.link); snackbar.show = true; snackbar.text = 'Copied successfully'">mdi-content-copy</v-icon>
                                                </template>
                                                <span>Copy Link</span>
                                            </v-tooltip>
                                        </div>
                                        <span v-if="selected.link == 'onsite'">Registered On-site</span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <div v-if="selected != null">
                    <v-card-actions v-if="selected.status == 'pending'" class="pb-4">
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="disapprove(selected.id)">
                            Disapprove
                        </v-btn>
                        <v-btn color="success" class="mr-3" @click="approve(selected.id)">
                            Approve
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <AlertDialog :title="alert.title" :body="alert.subtitle" :model="alert.show" @close="alert.show = false"></AlertDialog>
        <ConfirmDialog :title="dialog.title" :body="dialog.subtitle" :confirmColor="dialog.confirmColor" :confirmText="dialog.confirmText" :model="dialog.show" @close="dialog.show = false" @confirm="dialog.function()"></ConfirmDialog>
    </div>
</template>

<style>
#vsel * {
    font-size: 12px !important;
    /* width: 1%;
    min-width: 150px; */
}

#vsel {
    width: 128px;
}
</style>

<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import DeviceSelect from "@/components/DeviceSelect.vue";
import QrCodeViewer from "@/components/QrCodeViewer.vue";
import ProgressDialog from "./ProgressDialog.vue";
export default {
    components: { ConfirmDialog, AlertDialog, DeviceSelect, QrCodeViewer, ProgressDialog },
    props: ['value', 'refresh', 'eventId'],
    data: () => ({
        progressDialog: true,
        loaded: false,
        statuses: [
            { name: 'All', value: '' },
            { name: 'Pending', value: 'pending' },
            { name: 'Approved', value: 'approved' },
            { name: 'Disapproved', value: 'disapproved' },
        ],
        info: false,
        status: null,
        alert: {
            show: false,
            title: '',
            subtitle: '',
        },
        dialog: {
            show: false,
            title: '',
            subtitle: '',
            confirmColor: '',
            confirmText: '',
            function: () => { },
        },
        snackbar: {
            show: false,
            text: '',
        },
        dialog: false,
        search: null,
        selected: null,
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Recipient", value: "recipient" },
            { text: "Full Name", value: "name" },
            { text: "Registered", value: "createdAt" },
            { text: "Link Used", value: "link" },
            { text: "Status", value: "status", sortable: false },
            { text: "Actions", value: "actions", align: 'right' },
        ],
        users: [],
    }),
    watch: {
        async refresh(refresh) {
            if (refresh == true) {
                this.search = null;
                this.loaded = false;
                await this.refreshRegistrations({ link: this.value })
                this.loaded = true;
            }
        }
    },
    methods: {
        filterPerfectMatch(value, search) {
            return value != null && value === search
        },
        getColor(calories) {
            if (calories > 400) return 'red'
            else if (calories > 200) return 'orange'
            else return 'green'
        },
        approve() {
            this.dialog = {
                show: true,
                title: 'Approve',
                subtitle: 'Are you sure you want to approve this registration?',
                confirmColor: 'success',
                confirmText: 'Approve',
                function: async () => {
                    this.hideDialog();
                    var result = await this.post('/registration/edit', {
                        'id': this.selected.id,
                        'status': 'approved',
                    });
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: 'Approved successfully.'
                    }
                    this.refreshRegistrations({ link: this.value })
                    this.refreshLinks()

                    var logs = {
                        actions: 'Approved Registration for ' + this.selected.name + 'under ' + this.$store.state.currentEvent.name + ' Event.',
                    };

                    await this.post('/logs/store', logs);
                }
            }
        },
        disapprove(id) {
            this.dialog = {
                show: true,
                title: 'Disapprove',
                subtitle: 'Are you sure you want to disapprove this registration?',
                confirmColor: 'error',
                confirmText: 'Disapprove',
                function: async () => {
                    var result = await this.post('/registration/edit', {
                        'id': this.selected.id,
                        'status': 'disapproved',
                    });
                    this.hideDialog();
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: 'Disapproved successfully.'
                    }
                    this.refreshRegistrations({ link: this.value })
                    this.refreshLinks()

                    var logs = {
                        actions: 'Disappproved Registration for ' + this.selected.name + 'under ' + this.$store.state.currentEvent.name + ' Event.',
                    };

                    await this.post('/logs/store', logs);
                }
            }
        },
        hideDialog() {
            this.dialog.show = false;
            this.info = false;
        },
        approveAll() {
            this.dialog = {
                show: true,
                title: 'Approve All',
                subtitle: 'Are you sure you want to approve all inside this table?',
                confirmColor: 'green',
                confirmText: 'Approve All',
                function: async () => {
                    this.dialog.show = false;
                    var data = { event: this.$route.params.id, link: this.value };
                    var result = await this.post('/registration/approve-all', data);
                    this.refreshRegistrations({ link: this.value })
                    this.refreshLinks()
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: result.message,
                    }

                    var logs = {
                        actions: 'Approved All Registrations for url ' + this.value + ' under ' + this.$store.state.currentEvent.name + ' Event.',
                    };

                    var response = await this.post('/logs/store', logs);
                },

                
            };
        },
        resendQrCode(item) {
            this.dialog = {
                show: true,
                title: 'Resend QR Code',
                subtitle: 'Resend this registration QR Code via SMTP?',
                confirmColor: 'green',
                confirmText: 'Resend',
                function: async () => {
                    this.dialog.show = false;
                    var data = { event: this.$route.params.id, link: this.value, registrationId: item.id };
                    var result = await this.post('/registration/resend-qrcode', data);
                    this.alert = {
                        show: true,
                        title: 'Success',
                        subtitle: result.message,
                    }
                },
            };
        },
        exportCSV() {
            var newList = this.renameFields(this.$store.state.registrations, {
                '_id': 'Registration ID',
                'name': 'Full Name',
                'company': 'Company',
                'position': 'Position',
                'email': 'Email',
                'mobile': 'Mobile Number',
                'status': 'Status',
                'createdAt': 'Registered',
                'recipient': 'Invitation Recipient',
                'link': 'Link Used',
            });

            newList = this.sortFieldsInObjects(newList, [
                'Registration ID',
                'Full Name',
                'Company',
                'Position',
                'Email',
                'Mobile Number',
                'Status',
                'Registered',
                'Invitation Recipient',
                'Link Used',
            ])

            newList = this.formatObjectArray(newList, {
                'Mobile Number': (value) => `+63${value}`,
                'Link Used': (value) => {
                    if (value == 'onsite') return 'Registered On-site';
                    return `${window.location.origin}/sign-up/${value}`;
                }
            })

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, `${this.$store.state.currentEvent.name} Registrations.csv`);
        },
    },
    async mounted() {
        this.loaded = false;
        await this.refreshRegistrations({ link: this.value })
        this.loaded = true;
    },
};
</script>

<style>
.dec {
    text-decoration: none;
    color: black;
}
</style>
