<template>
    <Admin title="Account Settings">
        <v-container>
            <v-row class="justify-center mt-12">
                <v-col lg="8">
                    <v-card>
                        <v-card-title>Change Password</v-card-title>
                        <!-- <v-card-subtitle>
                            Registration form for onsite visitors.
                        </v-card-subtitle> -->
                        <v-card-text>
                            <v-form v-model="valid" v-on:submit="changePassword" ref="form">
                                <v-text-field label="Full Name" name="fullname" v-model="fullname" :rules="requiredRules" required outlined />
                                <v-text-field label="Email" name="email" v-model="email" :rules="requiredRules" required outlined />
                                <v-text-field name="password" label="New Password" v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="show ? 'text' : 'password'" @click:append="show = !show" required outlined error-count="7"></v-text-field>
                                <v-btn style="border-radius: 8px" color="blue darken-4" dark block type="submit" large>Submit</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" v-on:close="dialog.show = false"></AlertDialog>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
export default {
    components: { Admin, AlertDialog },
    data: () => ({
        loading: true,
        pageError: false,
        left: 0,
        dialog: {
            show: false,
            title: '',
            body: ``,
        },
        valid: true,
        event: null,
        fullname: "",
        email: "",
        password: "",
        show: false,
        userId: "",
    }),
    methods: {
        async getCurrentUser () {
            var response = await this.post('/refresh');
            this.fullname = response.data.fullname;
            this.email = response.data.email;
            this.userId = response.data.id;
        },
        async changePassword(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                var result = await this.post("/changePassword", {
                    id: this.userId,
                    name: this.fullname,
                    email: this.email,
                    password: this.password,
                });
                this.dialog.show = true;
                if (result.status != 'success') {
                    this.dialog.title = 'Oops!';
                    this.dialog.body = result.message;
                }
                else {
                    this.dialog.title = 'Success!';
                    this.dialog.body = `
                        <p>Password changed successfully.</p>
                    `;
                }       
            }
        },
    },
    mounted() {
        this.refreshEvents()
        this.getCurrentUser()
    }
};
</script>