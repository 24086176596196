import axios from "axios";

export default async function session(router, next) {
    var response = await axios({
        method: 'POST',
        url: `${process.env.VUE_APP_BACKEND}/refresh`,
        data: {},
        withCredentials: true,
    });

    if (response.data.status == 'verified') {
        return router.push({ name: 'event-manager' });
    } 

    return next();
}