<template>
    <div>
        <v-chip @click:close="confirmDialog.show = true" v-if="item.deviceDetails != null" :color="item.left == null ? 'error' : 'success'" dark small>{{  item.deviceDetails.name  }}</v-chip>
        <v-btn small @click="showDeviceModal" v-if="item.deviceDetails == null">Assign</v-btn>
        <v-dialog v-model="deviceModal" max-width="500px">
            <v-card>
                <v-card-title>
                    Device
                </v-card-title>
                <v-card-text>
                    <v-select :items="$store.state.devices" v-model="selectDevice" label="Assign Device" item-value="id" item-text="name"></v-select>
                </v-card-text>
            </v-card>
        </v-dialog>

        <ConfirmDialog :model="confirmDialog.show" :title="confirmDialog.title" :body="confirmDialog.subtitle" confirmColor="error" confirmText="Remove" @confirm="removeDevice" @close="confirmDialog.show = false"></ConfirmDialog>
    </div>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue';
export default {
    props: ["item", "open"],
    data: () => ({
        deviceModal: null,
        selectDevice: null,
        confirmDialog: {
            show: false,
            title: 'Unassign Device',
            subtitle: 'Are you sure to unassign this device?',
        }
    }),
    methods: {
        showDeviceModal() {
            this.deviceModal = true;
        },
        async removeDevice() {
            var result = await this.post("/device/remove", {
                regId: this.item.id,
                devId: this.item.deviceDetails.id,
            });
            this.selectDevice = null;
            this.refreshDevices();
            this.refreshAttendance();
            this.confirmDialog.show = false;
        }
    },
    watch: {
        async selectDevice(s) {
            if (s != null) {
                this.deviceModal = false;
                var result = await this.post("/device/select", {
                    regId: this.item.id,
                    devId: s,
                });
                this.refreshDevices();
                this.refreshAttendance();
            }
        }
    },
    components: { ConfirmDialog }
};
</script>

<style>
</style>