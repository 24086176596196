<template>
    <div>
        <v-btn color="success" small @click="model = true">
            <v-icon>mdi-qrcode</v-icon>
        </v-btn>
        <v-dialog v-model="model" max-width="600px" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-center" style="width: 100%">{{ item.name }}</span>
                </v-card-title>
                <v-card-text>
                    <img :src="url ?? ''" alt="qrcode" width="100%">
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="model = false"> DONE </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QrCode from 'qrcode';


export default {
    props: ["item","eventId"],
    data: () => ({
        model: false,
        url: '',
    }),
    methods: {
        closeModal() {
            this.$emit("close", false);
        },
    },
    mounted() {
        var details = {
            id: this.item.id,
            event: this.$props.eventId,
        };

        QrCode.toDataURL(JSON.stringify(details), (err, url) => {
            this.url = url;
        })
    }
};
</script>

<style>

</style>