import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import * as moment from 'moment-timezone';
import IdleVue from "idle-vue";
const eventsHub = new Vue();
import { AuthenticationContext } from 'vue-adal'

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: 900000, 
  });

Vue.config.productionTip = false

import VueSocketIO from 'vue-socket.io'

Vue.mixin({
    data() {
        return {
            server: 'http://localhost:3000',
            requiredRules: [
                (v) => !!v || "This field is required",
            ],
            specialRules: [
                (v) => !!v || "This field is required",
                (v) => (v && /^[_A-z]*((-|\s)*[A-z])*$/.test(v)) || "Please remove numbers & special characters."
            ],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+/.test(v) || "E-mail must be valid",
            ],
            mobileRules: [
                (v) => !!v || "Enter a valid mobile number",
                (v) => !isNaN(this.mobile) || "Enter a valid mobile number",
                (v) => {
                    if (v == null) {
                        return 'Enter a valid mobile number';
                    }
                    if (v.length != 10) {
                        return 'Please enter a 10 digit mobile number';
                    }
                    return true;
                }
            ],
            passwordRules: [
                (v) => !!v || "Password is required", 
                (v) => v.length >= 14 || "Min 14 characters",
                (v) => (v && /\d/.test(v)) || 'At least numerical character',
                (v) => (v && /[A-Z]{1}/.test(v)) || 'At least one upper case alphabet character',
                (v) => (v && /[a-z]{1}/.test(v)) || 'At least one lower case alphabet character',
                (v) => (v && /[^A-Za-z0-9]/.test(v)) || 'At least one special character',
                (v) => (v && !/([a-zA-Z0-9])\1/.test(v)) || 'Remove repetitive characters',
            ],
            loginPasswordRules: [
                (v) => !!v || "Password is required",
            ],
        }
    },
    methods: {
        async sendEmail() {
            var response = await this.post("/registration/list", { status: 'attended', event: this.$route.params.id });
            this.$store.state.attendance = response.data;
        },
        capitalize: str => str.charAt(0).toUpperCase() + str.slice(1),
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        post: async (url, data) => {
            var response = await axios({
                method: 'POST',
                url: `${process.env.VUE_APP_BACKEND}${url}`,
                data: data ?? {},
                withCredentials: true,
                headers: {
                    'Authorization': process.env.VUE_APP_DEVELOPMENT == 'true' ? `Bearer ${localStorage.token}` : null
                }
            })
            return response.data;
        },
        moment: moment,
        async refreshRegistrations(query) {
            var data = { event: this.$route.params.id };
            if (query != undefined) {
                if (query.link != '') {
                    data.link = query.link
                }
            }

            var response = await this.post("/registration/list", data);
            this.$store.state.registrations = response.data;
        },
        async refreshEvents() {
            var data = {
                date: { $gt: this.moment().subtract(1, 'days') }
            };
            if (this.$store.state.archived) {
                data = {
                    date: { $lt: this.moment().subtract(1, 'days') }
                };
            }
            var response = await this.post("/event/list", data);
            this.$store.state.events = response.data;
        },
        async availableEvents() {
            var data = {
                date: { $gt: this.moment().subtract(1, 'days') }
            };
            if (this.$store.state.archived) {
                data = {
                    date: { $lt: this.moment().subtract(1, 'days') }
                };
            }
            var response = await this.post("/event/available", data);
            this.$store.state.events = response.data;
        },
        async refreshLinks() {
            var response = await this.post("/link/list", {
                event: this.$route.params.id
            });
            this.$store.state.links = response.data;
        },
        async refreshAttendance() {
            var response = await this.post("/registration/list", { status: 'attended', event: this.$route.params.id });
            this.$store.state.attendance = response.data;
        },
        async refreshDevices() {
            var response = await this.post("/device/list", { assigned: null });
            this.$store.state.devices = response.data;
        },
        copyLink(mytext) {
            var eventTitle = this.$store.state.currentEvent.name;
            var eventDate = this.moment(this.$store.state.currentEvent.date).format('MMMM d, YYYY- h:m A');
            var input = document.createElement("textarea");
            input.value = `${location.origin}/sign-up/${mytext}`;
            document.body.appendChild(input);
            try {
                input.select();
                input.click();
                input.focus();
                var successful = document.execCommand("copy");
            }
            catch (err) {

            }
            document.body.removeChild(input);
        },
        convertCSV(data) {
            // Extract the field names from the first object in the array
            const fieldNames = Object.keys(data[0]);
            // Add the field names as the first row of the CSV
            const rows = [fieldNames, ...data.map(row => Object.values(row).map(value => String(value).includes(',') ? `"${value}"` : value))];
            // Use the map and join functions to create the CSV string
            const csv = rows.map(row => row.join(',')).join('\n');
            return csv;
        },
        removeFields(objects, fieldsToRemove) {
            const newObjects = [];
            for (let i = 0; i < objects.length; i++) {
                const object = objects[i];
                const newObject = {};
                for (let key in object) {
                    if (!fieldsToRemove.includes(key)) {
                        newObject[key] = object[key];
                    }
                }
                newObjects.push(newObject);
            }
            return newObjects;
        },
        renameFields(objects, rename) {
            return objects.map(object => {
                const updatedObject = {};
                for (let [key, value] of Object.entries(object)) {
                    updatedObject[rename[key] || key] = value;
                }
                return updatedObject;
            });
        },
        downloadCSV(csv, filename) {
            const csvFile = new Blob([csv], { type: "text/csv" });
            const downloadLink = document.createElement("a");
            downloadLink.download = filename;
            downloadLink.href = URL.createObjectURL(csvFile);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        },
        sortFieldsInObjects(objs, fieldOrder) {
            const sortedObjs = [];
            objs.forEach(obj => {
                const sortedObj = {};
                fieldOrder.forEach(field => {
                    if (obj.hasOwnProperty(field)) {
                        sortedObj[field] = obj[field];
                    }
                });
                sortedObjs.push(sortedObj);
            });
            return sortedObjs;
        },
        formatObjectArray(objectArray, objectFormat) {
            function objectFormatter(initialObject) {
                let resultObject = {};
                for (const key in initialObject) {
                    if (key in objectFormat) {
                        resultObject[key] = objectFormat[key](initialObject[key]);
                    } else {
                        resultObject[key] = initialObject[key];
                    }
                }
                return resultObject;
            }

            return objectArray.map(object => objectFormatter(object));
        },
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
