<template>
    <div>
        <v-btn color="success" @click="model = true;">
            GENERATE
        </v-btn>
        <v-dialog v-model="model" max-width="300" persistent>
            <v-card>
                <v-card-title class="text-h5">
                    {{ title }}
                </v-card-title>

                <v-card-text>
                    <!-- {{  }} -->
                    <span v-html="body"></span>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="closeModal"> OK </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ["title", "body", "model"],
    data: () => ({
        model: false,
    }),
    methods: {
        closeModal() {
            this.$emit("close", false);
        },
    },
};
</script>

<style>
</style>