<template>
    <Guest class="login fill-height">
        <v-container>
            <v-row class="justify-center mt-12">
                <v-col lg="8">
                    <v-card>
                        <v-card-title>Set New Password</v-card-title>
                        <v-card-text>
                            <v-form v-model="valid" v-on:submit="newPassword" ref="form">
                                <v-text-field label="Full Name" name="fullname" v-model="fullname" :rules="requiredRules" required outlined />
                                <v-text-field label="Email" name="email" v-model="email" :rules="requiredRules" required outlined />
                                <v-text-field name="password" label="New Password" v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" required outlined error-count="7" @change="validate()" @input="validate()"></v-text-field>
                                <v-text-field name="confirmPassword" label="Confirm New Password" v-model="confirmPassword" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordConfirm" :type="showConfirmPassword ? 'text' : 'password'" @click:append="showConfirmPassword = !showConfirmPassword" required outlined error-count="7" @change="validate()" @input="validate()"></v-text-field>
                                <v-btn style="border-radius: 8px" color="blue darken-4" dark block type="submit" large>Submit</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" v-on:close="dialog.show = false"></AlertDialog>
    </Guest>
</template>

<script>
import Guest from "@/views/layouts/GuestLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
export default {
    components: { Guest, AlertDialog },
    data() {
        return {
            loading: true,
            pageError: false,
            left: 0,
            dialog: {
                show: false,
                title: '',
                body: ``,
            },
            valid: true,
            event: null,
            fullname: "",
            email: "",
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            userId: "",
            passwordConfirm: [
                v => !!v || "Confirm Password required",
                v => v===this.password || "Passwords do not match"
            ]
        };
    },
    methods: {
        async getUserDetails () {
            var response = await this.post('/getUserDetails', {
                id: this.$route.params.id
            });
            this.fullname = response.data.fullname;
            this.email = response.data.email;
            this.userId = response.data.id;
        },
        async newPassword(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                var result = await this.post("/newPassword", {
                    id: this.userId,
                    name: this.fullname,
                    email: this.email,
                    password: this.password,
                });
                this.dialog.show = true;
                if (result.status != 'success') {
                    this.dialog.title = 'Oops!';
                    this.dialog.body = result.message;
                }
                else {
                    this.dialog.title = 'Success!';
                    this.dialog.body = `
                        <p>Password changed successfully.</p>
                    `;
                    var response = await this.post('/login', {
                        email: this.email,
                        password: this.password,
                    });
                    if (response.status != 'success') {
                        this.dialog.show = true;
                        this.dialog.title = 'Oops!';
                        this.dialog.body = response.message;
                        this.progressDialog.show = false;
                        return;
                    }
                    else {
                        this.$store.state.token = response.data;
                        if (process.env.VUE_APP_DEVELOPMENT == 'true') localStorage.token = response.data;
                        this.$router.push({ name: 'event-manager' })
                    }
                }       
            }
        },
        async validate() {
            this.$refs.form.validate();
        }
    },
    mounted() {
        this.getUserDetails()
        this.$refs.form.validate()
    }
};
</script>