<template>
    <Guest class="login fill-height">
        <v-container class="px-6 fill-height">
            <v-row justify="center" align-items="center">
                <v-col lg="6">
                    <v-card class="pa-12">
                        <v-card-text>
                            <div class="mb-12">
                                <v-img alt="Vuetify Logo" contain src="@/assets/logo.png" transition="scale-transition" width="100" class="mx-auto" />
                            </div>
                            <h1 class="h1 text-center mb-6">Sign in</h1>
                            <p class="text-center">
                                Sign in as admin using your login credentials.
                                <!-- Sign in as admin using your Microsoft account. -->
                            </p>
                            <v-form v-model="valid" v-on:submit="login" ref="form" autocomplete="off">
                                <v-text-field name="email" label="E-mail" v-model="email" :rules="emailRules" required outlined></v-text-field>
                                <v-text-field name="password" label="Password" v-model="password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :rules="loginPasswordRules" :type="show ? 'text' : 'password'" @click:append="show = !show" required outlined></v-text-field>
                                <v-btn style="border-radius: 8px" color="blue darken-4" dark block large elevation="0" type="submit">Sign in</v-btn>
                            </v-form>
                            <!-- <v-btn style="border-radius: 8px" color="blue darken-4" dark block large elevation="0" @click="login()">Sign in</v-btn> -->
                            <div class="mt-5">
                                <center><a href="/forgot-password">Forgot Password?</a></center>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <AlertDialog :model="dialog.show" :title="dialog.title" :body="dialog.body" @close="dialog.show = false"></AlertDialog>

        <ProgressDialog :show="progressDialog.show" :title="progressDialog.title"></ProgressDialog>
    </Guest>
</template>

<script>
import Guest from "../layouts/GuestLayout.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import ProgressDialog from "@/components/ProgressDialog.vue";
export default {
    data: () => ({
        progressDialog: {
            show: false,
            title: 'Logging in'
        },
        dialog: {
            show: false,
            title: '',
            body: '',
        },
        valid: true,
        email: "",
        password: "",
        show: false,
    }),
    methods: {
        async login(e) {
            e.preventDefault();
            if (this.$refs.form.validate()) {
                this.progressDialog.show = true;
                var response = await this.post('/login', {
                    email: this.email,
                    password: this.password,
                });
                if (response.status != 'success' && response.status != 'expired') {
                    this.dialog.show = true;
                    this.dialog.title = 'Oops!';
                    this.dialog.body = response.message;
                    this.progressDialog.show = false;
                    return;
                }
                else if(response.resetStatus=="pending" || response.status == 'expired') {
                    var response = await this.post('/getId', {
                        email: this.email,
                    });
                    this.$router.push({ name: 'new-password', params: { id: response.id } });
                }
                else {
                    this.$store.state.token = response.data;
                    if (process.env.VUE_APP_DEVELOPMENT == 'true') localStorage.token = response.data;
                    this.$router.push({ name: 'event-manager' })
                }
                this.progressDialog.show = false;
            }
        },
        
    },
    components: {
        Guest,
        AlertDialog,
        ProgressDialog
    },
};
</script>