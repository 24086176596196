<template>
    <Admin title="Users">
        <v-container>
            <v-row class="justify-center justify-center mt-12">
                <v-col lg="10">
                    <v-card>
                        <v-card-title>
                            <div class="d-flex" style="width: 100%">
                                <div class="flex-grow-1 mr-3">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                                </div>
                                <div>
                                    <v-btn color="warning" @click="showAddUserDialog" :disabled="currentUser.role == 'admin'">Add</v-btn>
                                </div>
                            </div>
                        </v-card-title>

                        <v-data-table :headers="headers" :items="users" :search="search" class="elevation-1" :items-per-page="10">
                            <template v-slot:item.id="{ item }">
                                {{ users.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:item.role="{ item }">
                                {{ toTitleCase(item.role) }}
                            </template>
                            <template v-slot:item.createdAt="{ item }">
                                {{ moment(item.createdAt).format('MMMM DD, YYYY - h:mm A') }}
                            </template>
                            <template v-slot:item.status="{ item }">
                                <div v-if="item.status=='locked'">
                                    <v-btn  small @click="unlockUser(item);" :disabled="currentUser.id == item.id || currentUser.role == 'admin'">
                                        Unlock
                                    </v-btn>
                                </div>
                                <div v-else-if="item.status=='temporary locked'">
                                   Temporary Locked
                                </div>
                                <div v-else>
                                   Active
                                </div>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex">
                                    <v-btn color="error" small @click="removeUser(item);" class="mr-3" :disabled="currentUser.id == item.id || currentUser.role == 'admin'">
                                        Remove
                                    </v-btn>
                                </div>
                            </template>
                            <template v-slot:item.password="{ item }">
                                <div v-if="item.tempPasswordChangeStatus=='pending'" class="d-flex">
                                    <v-btn color="info" small @click="generatePassword(item);" class="mr-3" :disabled="currentUser.id == item.id || currentUser.role == 'admin'">
                                        Regenerate
                                    </v-btn>
                                </div>
                                <div v-else>No pending password change request</div>
                            </template>
                        </v-data-table>

                        <v-dialog v-model="userDialog" max-width="800px" transition="dialog-transition" persistent>
                            <v-card>
                                <v-card-title>
                                    {{ currentItem == null ? 'Add' : 'Edit' }} User
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="valid" v-on:submit.prevent="generateEvent" ref="form">
                                        <v-text-field label="Full Name" name="fullname" v-model="fullname" :rules="requiredRules" required outlined />
                                        <v-text-field label="E-mail" name="email" v-model="email" :rules="emailRules" required outlined />
                                    </v-form>
                                </v-card-text>
                                <v-card-actions v-if="currentItem == null">
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="userDialog = false">CANCEL</v-btn>
                                    <v-btn text color="success" @click="addUser">Create</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <ConfirmDialog :model="confirmDialog.show" :title="confirmDialog.title" :body="confirmDialog.body" confirmText="DELETE" confirmColor="red" v-on:close="confirmDialog.show = false" v-on:confirm="confirmDialog.onConfirm"></ConfirmDialog>
        <ConfirmDialog :model="confirmUnlockDialog.show" :title="confirmUnlockDialog.title" :body="confirmUnlockDialog.body" confirmText="UNLOCK" confirmColor="red" v-on:close="confirmUnlockDialog.show = false" v-on:confirm="confirmUnlockDialog.onConfirm"></ConfirmDialog>
        <AlertDialog :model="alertDialog.show" :title="alertDialog.title" :body="alertDialog.body" @close="alertDialog.show = false" :tempPassword="alertDialog.tempPassword"></AlertDialog>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import AlertDialog from '../../components/AlertDialog.vue';
import ConfirmDialog from '../../components/ConfirmDialog.vue';
export default {
    components: { Admin, AlertDialog, ConfirmDialog },
    data: () => ({
        userDialog: false,
        valid: false,
        currentItem: null,
        currentItemData: null,
        search: "",
        currentUser: {
            id: '00'
        },
        fullname: "",
        email: "",
        password: "",
        show: false,

        headers: [
            { text: "#", value: "id" },
            { text: "Full Name", value: "fullname" },
            { text: "Email", value: "email" },
            { text: "Role", value: "role", },
            { text: "Added", value: "createdAt", },
            { text: "Status", value: "status", },
            { text: "Action", value: "actions", sortable: false },
            { text: "Password", value: "password", sortable: false },
        ],
        users: [],
        alertDialog: {
            show: false,
            title: 'Oops!',
            body: 'You cannot edit the event participants less than the current number of registrees.',
            
        },
        confirmDialog: {
            show: false,
            title: 'Oops!',
            body: 'You cannot edit the event participants less than the current number of registrees.',
            onConfirm: (value) => {
            }
        },
        confirmUnlockDialog: {
            show: false,
            title: 'Oops!',
            body: 'You cannot edit the event participants less than the current number of registrees.',
            onConfirm: (value) => {
            }
        },
    }),
    methods: {
        showAddUserDialog() {
            this.userDialog = true;
        },
        async addUser() {
            if (this.$refs.form.validate()) {
                var response = await this.post('/user/add', {
                    fullname: this.fullname,
                    email: this.email,
                });

                if (response.status != 'success') {
                    this.alertDialog = {
                        title: 'Oops!',
                        body: response.message,
                        show: true,
                    }
                    return;
                }

                this.alertDialog = {
                    title: 'Success!',
                    body: response.message,
                    show: true,
                }

                this.userDialog = false;

                var response = await this.post('/user/list');
                this.users = response.data;
                await this.$refs.form.reset()
            }
        },
        async removeUser(user) {
            this.confirmDialog = {
                show: true,
                title: 'Are you sure?',
                body: 'Are you sure you want to remove this user? This cannot be undone.',
                onConfirm: async () => {
                    var response = await this.post('/user/delete', {
                        id: user.id,
                        email: user.email,
                    });

                    if (response.status != 'success') {
                        this.alertDialog = {
                            title: 'Oops!',
                            body: response.message,
                            show: true,
                            
                        }
                        return;
                    }

                    this.alertDialog = {
                        title: 'Success!',
                        body: response.message,
                        show: true,
                        
                    }

                    this.confirmDialog.show = false

                    var response = await this.post('/user/list');
                    this.users = response.data;
                }
            }
        },
        async unlockUser(user) {
            this.confirmUnlockDialog = {
                show: true,
                title: 'Are you sure?',
                body: 'Are you sure you want to unlock this user? This cannot be undone.',
                onConfirm: async () => {
                    var response = await this.post('/user/unlock', {
                        id: user.id,
                    });

                    if (response.status != 'success') {
                        this.alertDialog = {
                            title: 'Oops!',
                            body: response.message,
                            show: true,
                            
                        }
                        return;
                    }

                    this.alertDialog = {
                        title: 'Success!',
                        body: response.message,
                        show: true,
                        
                    }

                    this.confirmUnlockDialog.show = false

                    var response = await this.post('/user/list');
                    this.users = response.data;
                }
            }
        },
        async generatePassword(user) {
            
            var response = await this.post('/user/generate', {
                email: user.email,
            });

            if (response.status != 'success') {
                this.alertDialog = {
                    title: 'Oops!',
                    body: response.message,
                    show: true,
                }
                return;
            }

            this.alertDialog = {
                title: 'Success!',
                body: response.message,
                show: true,
                tempPassword: response.tempPassword,
            }

            this.userDialog = false;
        }
    },
    async mounted() {
        var response = await this.post('/user/list');
        this.users = response.data;

        var response2 = await this.post('/refresh');
        this.currentUser = response2.data;
    }
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>