<template>
    <Guest>
        <div class="d-flex justify-center align-center fill-height">
            <div class="col-8">
                <center>
                    <img src="@/assets/logo.png" alt="" height="100" />
                </center>
                <h1 class="text-center mt-6">
                    Welcome to Meralco Visitor Registration
                </h1>
                <p class="text-center">
                    Check you email inbox for you sign-up link if your invited here. Thank
                    you.
                </p>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from "../layouts/GuestLayout.vue";
export default {
    components: {
        Guest,
    },
};
</script>
