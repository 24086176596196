<template>
    <Admin title="Logs">
        <v-container>
            <v-row class="justify-center justify-center mt-12">
                <v-col lg="10">
                    <v-card>
                        <v-card-title>
                            <div class="d-flex" style="width: 100%">
                                <div class="flex-grow-1 mr-3">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                                </div> 
                                <div>
                                    <v-btn color="success" @click="exportCSV()" v-if="logs.length != 0">Export CSV</v-btn>
                                </div>   
                            </div>
                        </v-card-title>

                        <v-data-table :headers="headers" :items="logs" :search="search" class="elevation-1" :items-per-page="10">
                            <template v-slot:item.id="{ item }">
                                {{ logs.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:item.user="{ item }">
                                {{ item.user}}
                            </template>
                            <template v-slot:item.action="{ item }">
                                {{ toTitleCase(item.action) }}
                            </template>
                            <template v-slot:item.createdAt="{ item }">
                                {{ moment(item.createdAt).format('MMMM DD, YYYY - h:mm A') }}
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";

export default {
    components: { Admin },
    data: () => ({
        userDialog: false,
        valid: false,
        currentItem: null,
        currentItemData: null,
        search: "",

        headers: [
            { text: "#", value: "id" },
            { text: "Full Name", value: "user" },
            { text: "Action", value: "action" },
            { text: "Date", value: "createdAt", },
        ],
        logs: [],
    }),
    async mounted() {
        var response = await this.post('/logs');
        this.logs = response.data;

        var response2 = await this.post('/refresh');
        this.currentUser = response2.data;
    },
    methods: {
        exportCSV() {
            if (this.logs.length == 0) return alert('The audit logs list is empty.');

            var newList = this.renameFields(this.logs, {
                'id': 'ID',
                'user': 'Name',
                'action': 'Action',
                'createdAt': 'Date',
            });

            newList = this.sortFieldsInObjects(newList, [
                'ID',
                'Name',
                'Action',
                'Date',
            ])

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, 'Logs.csv');
        },
    },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>