<template>
    <Admin title="Reports">
        <v-container>
            <v-row class="justify-center justify-center mt-12">
                <v-col lg="10">
                    <v-card>
                        <v-card-title>
                            <div class="d-flex" style="width: 100%">
                                <div class="flex-grow-1 mr-3">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                                </div> 
                                <div>
                                    <v-btn color="success" @click="exportCSV()" v-if="events.length != 0">Export CSV</v-btn>
                                </div>   
                            </div>
                        </v-card-title>

                        <v-data-table :headers="headers" :items="events" :search="search" class="elevation-1" :items-per-page="10">
                            <template v-slot:item.id="{ item }">
                                {{ events.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:item.name="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:item.participants="{ item }">
                                {{ item.participants}}
                            </template>
                            <template v-slot:item.date="{ item }">
                                {{ moment(item.date).format('MMMM DD, YYYY - h:mm A') }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex">
                                    <v-btn color="success" small :to="`/admin/reports/${item.id}`">
                                        View
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";

export default {
    components: { Admin },
    data: () => ({
        userDialog: false,
        valid: false,
        currentItem: null,
        currentItemData: null,
        search: "",

        headers: [
            { text: "#", value: "id" },
            { text: "Event Name", value: "name" },
            { text: "No. of Participants", value: "participants" },
            { text: "Date", value: "date", },
            { text: "Actions", value: "actions", },
        ],
        events: [],
    }),
    async mounted() {
        var response = await this.post('/reports');
        this.events = response.data;
    },
    methods: {
        exportCSV() {
            var newList = this.renameFields(this.events, {
                'id': '#',
                'name': 'Event Name',
                'participants': 'No. of Participants',
                'date': 'Event Date',
            });

            newList = this.sortFieldsInObjects(newList, [
                'ID',
                'Event Name',
                'No. of Participants',
                'Event Date',
            ])

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, `EventsReport.csv`);
        },
    }
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>