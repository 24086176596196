<template>
    <Admin title="">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col lg="10">
                    <h1 class="white--text">{{  eventName ?? ''  }}</h1>
                    <v-card>
                        <v-card-title>
                            <div class="d-flex" style="width: 100%">
                                <div class="flex-grow-1 mr-3">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                                </div> 
                                <div>
                                    <v-btn color="success" @click="exportCSV()" v-if="registrations.length != 0">Export CSV</v-btn>
                                </div>   
                            </div>
                        </v-card-title>

                        <v-data-table :headers="headers" :items="registrations" :search="search" class="elevation-1" :items-per-page="10">
                            <template v-slot:item.id="{ item }">
                                {{ registrations.indexOf(item) + 1 }}
                            </template>
                            <template v-slot:item.name="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:item.position="{ item }">
                                {{ item.position }}
                            </template>
                            <template v-slot:item.company="{ item }">
                                {{ item.company }}
                            </template>
                            <template v-slot:item.email="{ item }">
                                {{ item.email }}
                            </template>
                            <template v-slot:item.link="{ item }">
                                {{ item.link}}
                            </template>
                            <template v-slot:item.device="{ item }">
                                {{ item.device}}
                            </template>
                            <template v-slot:item.arrivedAt="{ item }">
                                <div v-if="item.arrivedAt!='--'">  {{ moment(item.arrivedAt).format('MMMM DD, YYYY - h:mm A') }} </div>
                                <div v-else> {{item.arrivedAt}} </div>
                            </template>
                            <template v-slot:item.leftAt="{ item }">

                                <div v-if="item.leftAt!='--'">  {{ moment(item.arrivedAt).format('MMMM DD, YYYY - h:mm A') }} </div>
                                <div v-else> {{item.leftAt}} </div>

                            </template>
                            <template v-slot:item.status="{ item }">
                                <v-chip small color="warning" v-if="item.status == 'pending'">{{ capitalize(item.status) }}</v-chip>
                                <v-chip small color="success" v-if="item.status == 'approved'">{{ capitalize(item.status) }}</v-chip>
                                <v-chip small color="success" v-if="item.status == 'attended'">{{ capitalize(item.status) }}</v-chip>
                                <v-chip small color="error" v-if="item.status == 'disapproved'">{{ capitalize(item.status) }}</v-chip>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </Admin>
</template>

<script>
import Admin from "@/views/layouts/AdminLayout.vue";
import LinkGenerator from "@/components/LinkGenerator.vue";
import Links from "@/components/Links.vue";
import Events from "@/components/Events.vue";
import Attendance from "@/components/Attendance.vue";
import Registrations from "@/components/Registrations.vue";
export default {
    components: { Admin, Links, LinkGenerator, Events, Attendance, Registrations },
    props: ['id'],
    data: () => ({
        tab: null,
        eventName: "",
        search: "",

        headers: [
            { text: "#", value: "id" },
            { text: "Name", value: "name" },
            { text: "Company", value: "company", },
            { text: "Position", value: "position" },
            { text: "Email", value: "email", },
            { text: "Mobile No", value: "mobile", },
            { text: "Link", value: "link", },
            { text: "Device", value: "device", },
            { text: "Arrived At", value: "arrivedAt", },
            { text: "Left At", value: "leftAt", },
            { text: "Status", value: "status", },
        ],
        registrations: [],
    }),
    async mounted() {
        var result = await this.post('/reports/event/get', {
            id: this.$route.params.id,
        })
        this.registrations = result.data;
        this.eventName =  result.event
    },
    methods: {
        exportCSV() {
            var newList = this.renameFields(this.registrations, {
                'id': '#',
                'name': 'Name',
                'company': 'Company',
                'position': 'Position',
                'email': 'Email',
                'mobile': 'Mobile Number',
                'link': 'Link Used',
                'device': 'Device',
                'arrivedAt': 'Arrived At',
                'leftAt': 'Left At',
                'status': 'Status',
            });

            newList = this.sortFieldsInObjects(newList, [
                'ID',
                'Name',
                'Company',
                'Position',
                'Email',
                'Mobile Number',
                'Link',
                'Device',
                'Arrived At',
                'Left At',
                'Status',
            ])

            newList = this.formatObjectArray(newList, {
                'Status': (value) => this.toTitleCase(value),
                'Mobile Number': (value) => `+63${value}`,
                'Link': (value) => {
                    if (value == 'onsite') return 'Registered On-site';
                    return `${window.location.origin}/sign-up/${value}`;
                },
            })

            var csv = this.convertCSV(newList);
            this.downloadCSV(csv, `${this.eventName}_Report.csv`);
        },
    }
};
</script>

<style scoped>
.theme--light.v-tabs-items {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}
</style>