<template>
    <v-card>
        <v-card-title>
            <div class="row">
                <div class="col pr-0">
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details outlined dense></v-text-field>
                </div>
            </div>
        </v-card-title>

        <v-data-table :headers="headers" :items="$store.state.links" :search="search" class="elevation-1" :items-per-page="10">
            <!-- No data -->
            <template v-slot:no-data v-if="!loaded">
                <div class="d-flex align-center justify-center">
                    <div>
                        <v-progress-circular indeterminate color="green" size="24" width="3"></v-progress-circular>
                    </div>
                    <div class="ml-4">
                        <span>Loading data. Please wait...</span>
                    </div>
                </div>
            </template>

            <template v-slot:item.id="{ item }">
                {{ $store.state.links.indexOf(item) + 1 }}
            </template>
            <template v-slot:item.event="{ item }">
                {{ item.event.name }}
            </template>
            <template v-slot:item.sent="{ item }">
                <v-icon color="success" v-if="item.sent">mdi-check</v-icon>
                <span v-if="!item.sent">No</span>
            </template>
            <template v-slot:item.signups="{ item }">
                {{ item.signups ?? item.left }}
            </template>
            <template v-slot:item.url="{ item }">
                <span v-if="item.url == 'onsite'">--</span>
                <div class="d-flex" v-if="item.url != 'onsite'">
                    <a :href="`/sign-up/${item.url}`" class="dec" target="_blank">{{ item.url }}</a>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="copy mr-3" v-on="on" @click="copyLink(item.url)">mdi-content-copy</v-icon>
                        </template>
                        <span>Copy Link</span>
                    </v-tooltip>

                </div>
            </template>
            <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt != '--' ? moment(item.createdAt).format('MMMM DD, YYYY') : '--' }}
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                    <div>
                        <v-btn color="info" small @click="regSearch = item.url; regDialog = true; viewEventLogs(item.url)">View</v-btn>
                    </div>
                </div>
            </template>
        </v-data-table>

        <v-card-text>
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn color="info" @click="regSearch = ''; regDialog = true;">View All</v-btn>
            </div>
        </v-card-text>

        <v-dialog v-model="regDialog" max-width="1200px" transition="dialog-transition" persistent>
            <ArchiveRegistrations :eventId="this.$props['eventId']" :value="regSearch" @close="regDialog = false; $store.state.registrations = []" :refresh="regDialog"></ArchiveRegistrations>
        </v-dialog>

        <ProgressDialog :show="progressDialog.show" :title="progressDialog.title"></ProgressDialog>
    </v-card>
</template>

<style>
.v-data-table>.v-data-table__wrapper>table {
    border-spacing: 0 0.5rem;
}
</style>

<script>
import ArchiveRegistrations from "@/components/ArchiveRegistrations.vue";
import ProgressDialog from "./ProgressDialog.vue";

export default {
    props: ["eventId"],
    data: () => ({
        deleteMessage: 'Are you sure you want to delete this link?<br><br>All registrations that used this link will also be deleted.',
        loaded: false,
        progressDialog: {
            show: false,
            title: 'Logging in'
        },
        regDialog: false,
        regSearch: null,
        event: null,
        confirmDialog: false,
        deleteData: null,
        search: "",
        headers: [
            {
                text: "#",
                align: "start",
                value: "id",
            },
            { text: "Recipient", value: "email" },
            { text: "Created", value: "createdAt" },
            { text: "Sign-ups", value: "signups" },
            { text: "Registered", value: "registered" },
            { text: "Approved", value: "approved" },
            { text: "Emailed", value: "sent" },
            { text: "Link", value: "url", },
            { text: "Actions", value: "actions", sortable: false },
        ],
        links: [],
        alert: {
            show: false,
            title: '',
            subtitle: '',
        },
    }),
    methods: {
        async viewEventLogs (url) {
            var logs = {
                actions: 'View Event Link Registrations under ' + this.$store.state.currentEvent.name + ' Event. ' + 'URL: ' + url,
            };

            var response = await this.post('/logs/store', logs);
            xlog(response);
        },  
    },
    async mounted() {
       
        this.loaded = false;
        await this.refreshLinks();
        this.loaded = true;
    },
    components: { ArchiveRegistrations, ProgressDialog }
};
</script>

<style scoped>
.copy {
    cursor: pointer;
}

.dec {
    text-decoration: none;
    color: black;
}
</style>